/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef, useEffect, useMemo } from 'react';
import { useGLTF, Float } from '@react-three/drei';
import { useSnapshot } from 'valtio';
import { TextureLoader } from 'three';
import { uiState } from '../states/ui-state';
import { labelState } from '../states/labelState';

export function NftBottle(props) {
    const { nodes, materials } = useGLTF('/models/nft-bottle.glb');
    const snap = useSnapshot(labelState);
    const labelRef = useRef();

    const labelTexture = useMemo(() => {
        if (snap.imageCropped) {
            const texture = new TextureLoader()
                .setCrossOrigin('')
                .load(snap.imageCropped);
            texture.flipY = false;
            // texture.toneMapped = false;
            return texture;
        }
    }, [snap.imageCropped]);

    useEffect(() => {
        return () => {
            if (labelTexture) labelTexture.dispose();
        };
    }, [snap.imageCropped]);

    return (
        <group
            position={[props.position.x, props.position.y, props.position.z]}
            rotation={[props.rotation.x, props.rotation.y, props.rotation.z]}
            dispose={null}
        >
            <group
                name="bottle"
                position={[0, 1.21, 0]}
                rotation={[0, 0.65, 0]}
            >
                <mesh
                    name="foil"
                    castShadow
                    receiveShadow
                    geometry={nodes.foil.geometry}
                    material={materials.Gold}
                    position={[3.13, 1.57, 0.57]}
                    material-color={uiState.bottleFoilColor}
                />

                <mesh
                    name="bottle"
                    castShadow
                    receiveShadow
                    geometry={nodes.bottle.geometry}
                    material={materials.Glass}
                    position={[3.13, 1.57, 0.57]}
                    material-envMapIntensity={1.5}
                    material-color={'black'}
                >
                    <mesh
                        name="wine"
                        geometry={nodes.wine.geometry}
                        material={materials.wine_red}
                        position={[0, -2.29, 0]}
                    />
                    <mesh
                        name="label"
                        ref={labelRef}
                        receiveShadow
                        geometry={nodes.label.geometry}
                        position={[-0.54, -1.67, 0.78]}
                    >
                        {snap.imageCropped ? (
                            <meshStandardMaterial
                                map={labelTexture}
                                envMapIntensity={1.5}
                            />
                        ) : (
                            <meshStandardMaterial
                                map={materials['nft-label-back'].map}
                                envMapIntensity={1.5}
                            />
                        )}
                    </mesh>
                </mesh>
            </group>
        </group>
    );
}

useGLTF.preload('/models/nft-bottle.glb');
