import React, { useRef } from 'react';
import { useGLTF, Float, useMatcapTexture } from '@react-three/drei';

export function Logo(props) {
    const { nodes, materials } = useGLTF('/models/logo_decimated.glb');
    const [matcapTextureGold] = useMatcapTexture(
        '71623B_ECDE8C_30250A_ABA69A',
        64
    );

    return (
        <group {...props} dispose={null}>
            <mesh
                name="Logo"
                castShadow
                receiveShadow
                geometry={nodes.Logo.geometry}
                // material={nodes.Logo.material}
            >
                <meshMatcapMaterial matcap={matcapTextureGold} />
            </mesh>
        </group>
    );
}

useGLTF.preload('/models/logo_decimated.glb');
