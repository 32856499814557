import React, { useRef, useEffect } from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import { uiState, videoPlayerProxy } from '../states/ui-state';
import { useSnapshot } from 'valtio';

export const VideoJS = (props) => {
    const snap = useSnapshot(uiState);
    const videoRef = useRef(null);
    const playerRef = useRef(null);
    const { options, onReady } = props;

    useEffect(() => {
        // Make sure Video.js player is only initialized once
        if (!playerRef.current) {
            // The Video.js player needs to be _inside_ the component el for React 18 Strict Mode.
            const videoElement = document.createElement('video-js');

            videoElement.classList.add('vjs-big-play-centered');
            videoElement.classList.add('vjs-matrix');
            videoElement.classList.add('vjs-big-play-button');
            videoElement.classList.add('vjs-volume-level');
            videoElement.classList.add('vjs-play-progress');
            videoElement.classList.add('vjs-slider-bar');
            videoRef.current.appendChild(videoElement);

            const player = (playerRef.current = videojs(
                videoElement,
                options,
                () => {
                    // videojs.log('player is ready');
                    onReady && onReady(player);
                }
            ));

            // You could update an existing player in the `else` block here
            // on prop change, for example:
        } else {
            const player = playerRef.current;

            player.autoplay(options.autoplay);
            player.src(options.sources);
        }
        videoPlayerProxy.videoPlayerRef = player;
    }, [options, videoRef]);

    // Dispose the Video.js player when the functional component unmounts
    useEffect(() => {
        const player = playerRef.current;

        return () => {
            if (player && !player.isDisposed()) {
                player.dispose();
                playerRef.current = null;
            }
        };
    }, [playerRef]);

    const player = playerRef.current;

    useEffect(() => {
        // console.log('video update', uiState.frontShow);
        const player = playerRef.current;
        if (uiState.frontShow == 'video') {
            // if (player.paused()) {
            player.play();
            // }
        } else {
            player.pause();
        }
    }, [uiState.animEndUpdate]);

    return (
        <div
            className="video-container"
            style={{
                marginLeft: 'auto',
                marginRight: 'auto'
            }}
            hidden={!uiState.videoPlayerShow}
        >
            <div
                className="video-close"
                onClick={(e) => {
                    uiState.videoPlayerShow = false;
                    player.pause();
                }}
            >
                <div
                    style={{
                        position: 'absolute',
                        top: '.29rem',
                        left: '.45rem'
                    }}
                >
                    ✕
                </div>
            </div>
            <div className="video-player">
                <div data-vjs-player>
                    <div ref={videoRef} />
                </div>
            </div>
        </div>
    );
};

export default VideoJS;
