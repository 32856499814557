import React, { useState } from 'react';
import { uiState } from '../states/ui-state.js';
import { useSnapshot } from 'valtio';
import { motion, AnimatePresence } from 'framer-motion';
import Modal from './Modal/index.jsx';
import { useNavigate } from 'react-router-dom';

const HomeUi = (props) => {
    const snap = useSnapshot(uiState);
    const [modalOpen, setModalOpen] = useState(true);

    const navigate = useNavigate();

    const open = () => setModalOpen(true);
    const close = () => {
        uiState.clickedState = true;
        uiState.customLabelUIShow = false;
    };

    const copy = '';

    return (
        <div className="home-ui-container" hidden={!uiState.homeShow}>
            {modalOpen && (
                <Modal
                    modalOpen={modalOpen}
                    handleClose={close}
                    label={props.label}
                    text={copy}
                />
            )}
        </div>
    );
};
export default HomeUi;
