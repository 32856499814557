import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { uiState } from '../states/ui-state.js';
import { useSnapshot } from 'valtio';
import { motion } from 'framer-motion';
import Backdrop from './Backdrop/index.jsx';
import { BigNumber, utils } from 'ethers';
import {
    useActiveClaimConditionForWallet,
    useAddress,
    useClaimConditions,
    useClaimedNFTSupply,
    useClaimerProofs,
    useClaimIneligibilityReasons,
    useContract,
    useContractEvents,
    useContractMetadata,
    useUnclaimedNFTSupply,
    Web3Button,
    useNFT,
    ThirdwebNftMedia
} from '@thirdweb-dev/react';
import ThreeDNftRenderer from './ThreeDNftRenderer.jsx';
import { useCookies } from 'react-cookie';
import { Toaster, toast } from 'react-hot-toast';

const _contractAddress = '0x33f259D488072ECb12DE1Da40d860Cb9c62f1d5d';
const blockExplorerAddressLink = 'https://etherscan.io/address/';
const blockExplorerTransactionLink = 'https://etherscan.io/tx/';
const openseaLink =
    'https://opensea.io/assets/ethereum/' + _contractAddress + '/';
// const openseaLink =
// 'https://testnets.opensea.io/assets/mumbai/' + _contractAddress + '/';

const MintModal = () => {
    const [cookies] = useCookies(['aff']);
    const [hovered, setHovered] = useState(false);
    const over = (e) => (e.stopPropagation(), setHovered(true));
    const out = () => setHovered(false); //Setup Mouse hover
    useEffect(() => {
        if (hovered) document.body.style.cursor = 'pointer';
        return () => (document.body.style.cursor = 'auto');
    }, [hovered]);
    useSnapshot(uiState);

    const navigate = useNavigate();
    const handleClick = (e, state, _address, _error) => {
        console.log('mint action', cookies.aff);

        if (state == 'mint-close') {
            e.stopPropagation();
            uiState.mintPopShow = false;
            uiState.mintStatusShow = false;
            uiState.mintTransmitShow = false;
        }
        if (state == 'sale') {
            uiState.mintStatusShow = true;
            loadTrackingPixel();
            toast(
                'Your new Founder NFT is minted! \n\n See the mint status for links.',
                {
                    icon: '🍷',
                    style: {
                        border: '2px solid #ffffff4d',
                        borderRadius: '8px',
                        padding: '17px',
                        background: '#000000D3',
                        position: 'bottom-center',
                        color: '#fff',
                        fontSize: '1.0rem',
                        fontWeight: '400',
                        duration: 4000
                    }
                }
            );
        }
        if (state == 'transmitted') {
            uiState.mintTransmitShow = true;
            toast(
                'MAKE SURE TO CONFIRM THIS TRANSACTION IN YOUR WALLET OR NOTHING WILL HAPPEN! \n\n Please leave this browser window open until the full minting process is complete. ⌛ \n\n And then...\n\n Stay tuned...😊 ',
                {
                    icon: '⏰',
                    style: {
                        border: '2px solid #ffffff4d',
                        borderRadius: '8px',
                        padding: '17px',
                        background: '#000000D3',
                        position: 'bottom-center',
                        color: '#fff',
                        fontSize: '1.0rem',
                        fontWeight: '400'
                    }
                }
            );
        }
        if (state == 'error') {
            uiState.mintTransmitShow = true;
            toast(
                'Wallet error. 🦊\n\n Something went wrong. \n\n Please try again.',
                {
                    icon: '🚫',
                    style: {
                        border: '2px solid #ffffff4d',
                        borderRadius: '8px',
                        padding: '17px',
                        background: '#000000D3',
                        position: 'bottom-center',
                        color: '#fff',
                        fontSize: '1.0rem',
                        fontWeight: '400'
                    }
                }
            );
        }
        if (uiState.animLocation != 'nft') {
            navigate('nft');
        }
    };

    const loadTrackingPixel = () => {
        const sclParameters = {
            amount: 'amount',
            adv_order_id: 'adv_order_id',
            adv_user_id: 'adv_user_id',
            adv_param1: 'adv_param1',
            adv_param2: 'adv_param2',
            adv_param3: 'adv_param3',
            adv_param4: 'adv_param4',
            adv_param5: 'adv_param5',
            idfa: 'idfa',
            gaid: 'gaid'
        };

        function sclConversionPixelFn(sclParameters) {
            const cookieValue = document.cookie
                .split('; ')
                .find((row) => row.startsWith('click_id='))
                ?.split('=')[1];
            let trackingUrl =
                'https://cork.nftwineclub.com/track/goal-by-click-id?click_id={click_id}&goal_id=3&{adv_order_id}=';
            trackingUrl = trackingUrl.replace('{click_id}', cookieValue);
            if (sclParameters && Object.keys(sclParameters)?.length > 0) {
                const url = new URL(trackingUrl);
                Object.entries(sclParameters).forEach(([key, value]) => {
                    url.searchParams.set(key, value.toString());
                });
                trackingUrl = url.href;
            }
            const imgEl = document.createElement('img');
            imgEl.src = trackingUrl;
            imgEl.width = 1;
            imgEl.height = 1;
            document.body.insertAdjacentElement('beforeend', imgEl);
            console.log(
                'Tracking Pixel:',
                'Address:',
                uiState.mintTransactionHash,
                'Aff:',
                cookies.aff
            );
        }
        const pixel = sclConversionPixelFn(sclParameters);
        //log sale info in zapier zap
        fetch(
            'https://hooks.zapier.com/hooks/catch/1753534/33q01iv/?aff=' +
                cookies.aff +
                '&owner=' +
                uiState.nftOwner +
                '&addr=' +
                uiState.mintTransactionHash +
                '&nftid=' +
                uiState.nftID
        );
    };
    const { contract } = useContract(_contractAddress);
    const unclaimedSupply = useUnclaimedNFTSupply(contract);
    const claimedSupply = useClaimedNFTSupply(contract);

    const { data: claimEvent } = useContractEvents(contract, 'TokensClaimed', {
        subscribe: true
    });

    const numberClaimed = useMemo(() => {
        return BigNumber.from(claimedSupply.data || 0).toString();
    }, [claimedSupply]);

    const numberTotal = useMemo(() => {
        return BigNumber.from(claimedSupply.data || 0)
            .add(BigNumber.from(unclaimedSupply.data || 0))
            .toString();
    }, [claimedSupply.data, unclaimedSupply.data]);

    let nextNft = numberClaimed - 0; //convert to string by doing simple math

    const {
        data: nft,
        isLoading: isReadingNft,
        error
    } = useNFT(contract, nextNft);

    nextNft++; //increment to show next NFT to mint

    // console.log('CLAIMED:', claimedSupply, '\n', 'Next', numberClaimed);

    const truncateAddress = (address) => {
        return (
            address.substring(0, 6) +
            '...' +
            address.substring(address.length - 4)
        );
    };

    return (
        <>
            <Toaster
                position="top-right"
                toastOptions={{
                    duration: 22000
                }}
            />
            <div className="mint-pop" hidden={!uiState.mintPopShow}>
                <Backdrop cssName="backdrop" />
                <div className="mint-container">
                    <div
                        className="mint-close"
                        onClick={(e) => handleClick(e, 'mint-close')}
                    >
                        <div
                            style={{
                                position: 'absolute',
                                top: '.29rem',
                                left: '.45rem'
                            }}
                        >
                            ✕
                        </div>
                    </div>
                    <div
                        className="cardheaderBG"
                        style={{
                            position: 'relative',
                            top: '-26px',
                            height: '33px'
                        }}
                    >
                        <img
                            src="/images/logo-wide.png"
                            style={{
                                width: '60%',
                                position: 'relative',
                                left: '62px',
                                top: '-15px'
                            }}
                        />
                    </div>
                    <div style={{ position: 'relative' }}>
                        <div
                            // className="gradient-text"
                            style={{
                                color: '#d3d3d3',
                                fontWeight: '400',
                                fontSize: '1.4rem',
                                textAlign: 'center',
                                position: 'relative',
                                top: '-5px',
                                left: '0'
                            }}
                        >
                            FOUNDERS EDITION
                        </div>
                    </div>
                    <div style={{ marginTop: '-50px' }}>
                        {isReadingNft ? (
                            <div>
                                <div
                                    className="gradient-text centerItPlease"
                                    style={{
                                        color: '#d1ad38',
                                        fontWeight: '600',
                                        fontSize: '1.6rem',
                                        textAlign: 'center',
                                        position: 'relative',
                                        top: '0px',
                                        left: '0px'
                                    }}
                                >
                                    <img
                                        style={{
                                            marginTop: '65px',
                                            width: '260px',
                                            borderRadius: '11px'
                                        }}
                                        src="./images/nft-cover.png"
                                    />
                                </div>
                                <div
                                    style={{
                                        marginTop: '1px',
                                        fontWeight: '600',
                                        fontSize: '22px'
                                    }}
                                    className="centerItPlease"
                                >
                                    <p>LOADING NFT...</p>
                                </div>
                            </div>
                        ) : (
                            <>
                                {uiState.mintStatusShow ? (
                                    <ThreeDNftRenderer
                                        contract={contract}
                                        nftID={uiState.nftID}
                                    />
                                ) : (
                                    <div className="nft-container">
                                        <ThirdwebNftMedia
                                            metadata={nft.metadata}
                                            key={nft.metadata.id}
                                            width={'400px'}
                                            height={'400px'}
                                        />
                                    </div>
                                )}
                                <div
                                    className="cardheaderBG"
                                    style={{
                                        position: 'relative',
                                        top: '-3px',
                                        height: '180px'
                                    }}
                                >
                                    <div
                                        style={{
                                            marginTop: '5px',
                                            fontWeight: 'bold',
                                            fontSize: '1.2rem'
                                        }}
                                        className="gradient-text-gold centerItPlease"
                                    >
                                        {uiState.mintStatusShow
                                            ? 'YOUR NEW NFT:  #' + (nextNft - 1)
                                            : 'CLAIM FOUNDER NFT #' + nextNft}
                                    </div>
                                    <div
                                        className="centerItPlease"
                                        style={{
                                            marginTop: '-8px'
                                        }}
                                    >
                                        {claimedSupply && unclaimedSupply ? (
                                            <p>
                                                {numberClaimed}
                                                {' of '}
                                                {numberTotal} Minted
                                            </p>
                                        ) : (
                                            <p>Loading...</p>
                                        )}
                                    </div>

                                    {uiState.mintStatusShow ? (
                                        <div
                                            style={{
                                                marginTop: '-8px',
                                                padding: '11px'
                                            }}
                                        >
                                            <div>
                                                <div
                                                    style={{
                                                        marginTop: '-5px',
                                                        marginLeft: '8px',
                                                        padding: '11px'
                                                    }}
                                                >
                                                    Transaction:&nbsp;&nbsp;&nbsp;
                                                    <a
                                                        style={{
                                                            color: 'red'
                                                        }}
                                                        href={
                                                            blockExplorerTransactionLink +
                                                            uiState.mintTransactionHash
                                                        }
                                                        target="_blank"
                                                    >
                                                        {truncateAddress(
                                                            uiState.mintTransactionHash
                                                        )}
                                                    </a>
                                                </div>
                                                <div
                                                    style={{
                                                        marginTop: '-11px',
                                                        marginLeft: '8px',
                                                        padding: '11px'
                                                    }}
                                                >
                                                    Owner:&nbsp;&nbsp;&nbsp;
                                                    <a
                                                        href={
                                                            blockExplorerAddressLink +
                                                            uiState.nftOwner
                                                        }
                                                        style={{
                                                            color: 'red'
                                                        }}
                                                        target="_blank"
                                                    >
                                                        {truncateAddress(
                                                            uiState.nftOwner
                                                        )}
                                                    </a>
                                                </div>
                                                <div
                                                    style={{
                                                        marginTop: '-11px',
                                                        marginLeft: '8px',
                                                        padding: '11px'
                                                    }}
                                                >
                                                    View on
                                                    OpenSea:&nbsp;&nbsp;&nbsp;
                                                    <a
                                                        style={{
                                                            color: 'red'
                                                        }}
                                                        href={
                                                            openseaLink +
                                                            uiState.nftID
                                                        }
                                                        target="_blank"
                                                    >
                                                        {truncateAddress(
                                                            uiState.nftOwner
                                                        )}
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <></>
                                    )}
                                </div>

                                {!uiState.mintStatusShow ? (
                                    <div
                                        className="nft-mint-button"
                                        style={{
                                            marginTop: '-122px',
                                            padding: '8px',
                                            paddingBottom: '33px'
                                        }}
                                    >
                                        <Web3Button
                                            accentColor="darkred"
                                            contractAddress={_contractAddress}
                                            action={(contract) =>
                                                contract.erc721.claim(1)
                                            }
                                            onSuccess={(result) => {
                                                console.log(
                                                    'Success!',
                                                    result,
                                                    'Hash:',
                                                    result[0].receipt
                                                        .transactionHash,
                                                    'Owner',
                                                    result[0].receipt.from
                                                );
                                                uiState.mintTransactionHash =
                                                    result[0].receipt.transactionHash;
                                                uiState.nftOwner =
                                                    result[0].receipt.from;
                                                uiState.nftIndex =
                                                    result[0].receipt.transactionIndex;
                                                uiState.nftID = nextNft - 1;

                                                handleClick(
                                                    '',
                                                    'sale',
                                                    result[0].receipt
                                                        .transactionHash
                                                );
                                            }}
                                            onError={(error) => {
                                                handleClick('', 'error', error);
                                                console.log(
                                                    'Something went wrong!',
                                                    error
                                                );
                                            }}
                                            onSubmit={() => {
                                                handleClick('', 'transmitted');
                                                console.log(
                                                    'Transaction submitted'
                                                );
                                            }}
                                        >
                                            CLAIM #{nextNft} - $5000 USDC
                                        </Web3Button>
                                    </div>
                                ) : (
                                    <></>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default MintModal;
