import { Suspense } from 'react';
import { useVideoTexture, useAspect, useTexture } from '@react-three/drei';

const VideoTexture = (props) => {
    function VideoMaterial({ url }) {
        const texture = useVideoTexture(url);
        return <meshBasicMaterial map={texture} toneMapped={false} />;
    }

    function FallbackMaterial({ url }) {
        const texture = useTexture(url);
        return <meshBasicMaterial map={texture} toneMapped={false} />;
    }

    // const videoSize = useAspect(1920, 1080);
    return (
        <>
            <group className="video">
                <mesh >
                    <planeGeometry />
                    <Suspense
                        fallback={
                            <FallbackMaterial url="./images/nft-cover.png" />
                        }
                    >
                        <VideoMaterial url={props.url} />
                    </Suspense>
                </mesh>
            </group>
        </>
    );
};

export default VideoTexture;
