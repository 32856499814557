import React, { useState, useEffect } from 'react';
import { uiState } from '../states/ui-state.js';
import { useSnapshot } from 'valtio';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';

const Footer = () => {
    const [hovered, setHovered] = useState(false);
    const over = (e) => (e.stopPropagation(), setHovered(true));
    const out = () => setHovered(false); //Setup Mouse hover
    useEffect(() => {
        if (hovered) document.body.style.cursor = 'pointer';
        return () => (document.body.style.cursor = 'auto');
    }, [hovered]);
    const snap = useSnapshot(uiState);

    const navigate = useNavigate();

    const handleClick = (e, state) => {
        e.stopPropagation();
        uiState.animReady = true;
        uiState.frontShow = 'optin';
        if (state != uiState.animLocation) {
            navigate(state);
        } else if (state == 'front') {
            uiState.optinShow = true;
        }
    };

    const metaverseLink =
        'https://www.spatial.io/s/NFT-Wine-Club-Office-66467c6e5fef5bd50b11e474?share=4629812013570562442';

    const openInNewTab = (e, url) => {
        e.stopPropagation();
        window.open(url, '_blank', 'noreferrer');
    };

    return (
        <>
            <div className="footer-container" hidden={!uiState.footerShow}>
                <div className="footer">
                    <motion.p
                        className="gradient-text"
                        onClick={(e) => handleClick(e, 'about')}
                        onPointerOver={over}
                        onPointerOut={out}
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.9 }}
                    >
                        ABOUT
                    </motion.p>
                    <motion.p
                        className="gradient-text"
                        // onClick={(e) => handleClick(e, 'front')}
                        onClick={(e) => openInNewTab(e, metaverseLink)}
                        onPointerOver={over}
                        onPointerOut={out}
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.9 }}
                    >
                        METAVERSE
                    </motion.p>

                    <motion.div
                        onClick={(e) => handleClick(e, '/')}
                        onPointerOver={over}
                        onPointerOut={out}
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.9 }}
                    >
                        <img
                            src="/images/nft-logo.png"
                            style={{ marginTop: '0px', width: '50px' }}
                        />
                    </motion.div>
                    <motion.p
                        className="gradient-text"
                        onClick={(e) => handleClick(e, 'create')}
                        onPointerOver={over}
                        onPointerOut={out}
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.9 }}
                    >
                        CREATE
                    </motion.p>
                    <motion.p
                        className="gradient-text"
                        onClick={(e) => handleClick(e, 'wine')}
                        onPointerOver={over}
                        onPointerOut={out}
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.9 }}
                    >
                        WINE
                    </motion.p>
                </div>
            </div>
        </>
    );
};

export default Footer;
