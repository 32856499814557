import Cropper from 'react-cropper';
import { useRef, useEffect, useState } from 'react';
import { labelState } from '../../states/labelState';
import {
    uiState,
    filePickerState,
    colorPickerState
} from '../../states/ui-state';
import { useSnapshot } from 'valtio';
import 'cropperjs/dist/cropper.min.css';
import './CustomLabel.css';
import { useThree } from '@react-three/fiber';
import { Html } from '@react-three/drei';
import { SliderPicker } from 'react-color';

export default function CustomLabelUi() {
    const snap = useSnapshot(labelState);
    const snap2 = useSnapshot(uiState);
    const cropperRef = useRef();
    const { size } = useThree();

    const handleCropEnd = () => {
        labelState.imageCropped = cropperRef.current.cropper
            .getCroppedCanvas()
            .toDataURL('image/jpeg');
        uiState.customLabelImageSet = true;
    };

    const handleReady = () => {
        if (
            labelState.imageURL == './images/0121_NFT_Label.png' ||
            uiState.customLabelImageSet
        ) {
            return;
        } else {
            labelState.imageCropped = cropperRef.current.cropper
                .getCroppedCanvas()
                .toDataURL('image/jpeg');
            uiState.customLabelImageSet = true;
        }
    };

    return (
        <>
            {snap2.customLabelUIShow && (
                <Html center zIndexRange={[1000, 0]}>
                    <div className={'wrapper'}>
                        <Cropper
                            ref={cropperRef}
                            src={snap.imageURL}
                            initialAspectRatio={3 / 5}
                            aspectRatio={3 / 5}
                            cropend={handleCropEnd}
                            ready={handleReady}
                            minContainerWidth={snap2.isMobile ? 175 : 200}
                            viewMode={2}
                        />
                        <Input />
                        <ColorPick />
                    </div>
                </Html>
            )}
        </>
    );
}

const Input = () => {
    const handleChange = (e) => {
        uiState.customLabelImageSet = false;
        labelState.imageURL = URL.createObjectURL(e.target.files[0]);
    };
    return (
        <>
            <input
                className="imageInput"
                type="file"
                accept="image/*"
                onChange={handleChange}
                ref={filePickerState.filePickerRef}
            />
        </>
    );
};
const ColorPick = () => {
    const [foilColor, setFoilColor] = useState('#d10000');
    const handleChange = (objColor, e) => {
        setFoilColor(objColor.hex);
        uiState.bottleFoilColor = objColor.hex;
    };
    return (
        <SliderPicker
            className="sliderpicker"
            color={foilColor}
            onChange={handleChange}
        />
    );
};
