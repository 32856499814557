import React, { useState } from 'react';
import { motion } from 'framer-motion';
import Backdrop from '../Backdrop';
import { uiState } from '../../states/ui-state.js';
import { useNavigate } from 'react-router-dom';

const Modal = ({ handleClose, text, label }) => {
    const [isOpen, setIsOpen] = useState(uiState.homeReveal);

    const navigate = useNavigate();

    const handleClick = (e, state) => {
        e.stopPropagation();
        navigate(state);
        uiState.clickedState = true;
        uiState.customLabelUIShow = false;
        uiState.homeReveal = false;
        navigate('front');
        uiState.frontShow = 'video';
        setIsOpen(uiState.homeReveal);
    };

    return (
        <>
            <Backdrop cssName="backdrop" clickIt={true} />
            <motion.div
                // onClick={(e) => handleClick(e, 'front')}
                className="home-ui"
            >
                <div className="cardheaderBG">
                    <img
                        src="/images/logo-wide.png"
                        style={{
                            width: '333px',
                            position: 'relative',
                            top: '-17px',
                            left: '6px'
                        }}
                    />
                </div>
                <div
                    style={{
                        textAlign: 'center',
                        color: '#ffffff',
                        // fontWeight: 'bold',
                        fontSize: '22px',
                        position: 'relative',
                        top: '10px',
                        height: '296px',
                        width: '350px'
                    }}
                >
                    <div
                        className="gradient-text"
                        style={{
                            width: '333px',
                            position: 'relative',
                            left: '25px',
                            top: '-3px',
                            textAlign: 'left'
                        }}
                    >
                        A BOLD NEW WAY
                        <br /> TO EXPERIENCE
                        <p
                            className="gradient-text-red"
                            style={{
                                position: 'relative',
                                left: '0px',
                                top: '-60px',
                                textAlign: 'left',
                                color: '#570000',
                                fontSize: '55px',
                                position: 'relative',
                                fontWeight: '700'
                            }}
                        >
                            WINE
                        </p>
                    </div>
                    <img
                        src="/images/nftbottle-right-grapes.png"
                        style={{
                            width: '380px',
                            position: 'relative',
                            top: '-311px',
                            left: '-20px'
                        }}
                    />
                </div>

                <motion.button
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.99 }}
                    onClick={(e) => handleClick(e, 'front')}
                    className={'home-button'}
                >
                    <span>{label} &nbsp;</span>
                </motion.button>
            </motion.div>
        </>
    );
};

export default Modal;
