import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import { optinSchema } from '../schemas';
import axios from 'axios';
import { uiState } from '../states/ui-state.js';
import { useSnapshot } from 'valtio';
import { motion } from 'framer-motion';
import Backdrop from './Backdrop';
import { useNavigate } from 'react-router-dom';

const OptinForm = (props) => {
    const snap = useSnapshot(uiState);
    const [hovered, setHovered] = useState(false);
    const over = (e) => (e.stopPropagation(), setHovered(true));
    const out = () => setHovered(false); //Setup Mouse hover
    useEffect(() => {
        if (hovered) document.body.style.cursor = 'pointer';
        return () => (document.body.style.cursor = 'auto');
    }, [hovered]);

    const handleClick = (e, state) => {
        e.stopPropagation();
        uiState.optinShow = false;
    };

    const navigate = useNavigate();

    const onSubmit = async (values, actions) => {
        // console.log('values', values);
        // console.log(actions);
        // const requestOptions = {
        // 	method: 'POST',
        // 	// headers: { 'Content-Type': 'application/json' },
        // 	mode: 'no-cors',
        // 	body: JSON.stringify({
        // 		'data-kt-value': 'YoixlhPmzRZa',
        // 		'data-kt-owner': 'Rg3oVK6g',
        // 		'data-kt-type': 'optin',
        // 		email: values.email,
        // 	}),
        // };

        const formData = {
            'data-kt-value': 'YoixlhPmzRZa',
            'data-kt-owner': 'Rg3oVK6g',
            // 'data-kt-type': 'optin',
            email: values.email
        };

        try {
            // make axios post request
            const response = await axios({
                method: 'post',
                mode: 'no-cors',
                url: 'https://app.kartra.com/process/add_lead/YoixlhPmzRZa',
                data: formData,
                headers: { 'Content-Type': 'multipart/form-data' }
            }).then((response) => {
                // console.log('Response', response);
                navigate('thankyou');
            });
        } catch (error) {
            // console.log('Error', error);

            //TODO fix this stupid shit - Axios always sends Network Error on localhost
            //TODO hacked to animate after form submit, need response to trigger animation instead of error
            // uiState.animTarget = 'thankyou';
            navigate('thankyou');
            // uiState.optinShow = false;
        }

        actions.resetForm();
    };

    const {
        values,
        errors,
        touched,
        isSubmitting,
        handleBlur,
        handleChange,
        handleSubmit
    } = useFormik({
        initialValues: {
            email: ''
            // age: '',
            // password: '',
            // confirmPassword: '',
        },
        validationSchema: optinSchema,
        onSubmit
    });

    // console.log(errors);

    return (
        <div hidden={!uiState.optinShow}>
            <Backdrop cssName="optin-backdrop" />
            <div className="container">
                <div
                    className="optin-close"
                    onClick={(e) => handleClick(e, 'front')}
                >
                    <div
                        style={{
                            position: 'absolute',
                            top: '.41rem',
                            left: '.61rem'
                        }}
                    >
                        ✕
                    </div>
                </div>
                <div className="cardheaderBG" style={{ position: 'relative' }}>
                    <img
                        src="/images/logo-wide.png"
                        style={{
                            width: '100%',
                            position: 'relative',
                            top: '-16px',
                            left: '3px'
                        }}
                    />
                </div>
                <div style={{ position: 'relative' }}>
                    <img
                        src="/images/grapes1.jpg"
                        style={{
                            width: '100%',
                            position: 'relative',
                            top: '-22px',
                            left: '0',
                            right: '0',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            borderRadius: '8px'
                        }}
                    />
                    <div
                        // className='gradient-text-gold'
                        style={{
                            color: '#d1ad38',
                            fontWeight: '700',
                            fontSize: '1.5rem',
                            textAlign: 'center',
                            position: 'relative',
                            top: '-5px',
                            left: '0'
                        }}
                    >
                        MERGING NFTs & WINE
                    </div>
                    <div
                        style={{
                            color: '#fff',
                            fontWeight: '400',
                            fontSize: '1.25rem',
                            textAlign: 'center',
                            position: 'relative',
                            top: '5px',
                            left: '0',
                            paddingBottom: '25px'
                        }}
                    >
                        Napa Valley Wines
                        <br /> Tied to the Blockchain!
                        <br />
                        <br /> Enter your email to learn
                        <br /> about our exclusive wine club.
                    </div>
                </div>
                <form onSubmit={handleSubmit} autoComplete="off">
                    <input
                        value={values.email}
                        onChange={handleChange}
                        id="email"
                        type="email"
                        placeholder="Enter email"
                        onBlur={handleBlur}
                        className={
                            errors.email && touched.email ? 'input-error' : ''
                        }
                    />
                    {errors.email && touched.email && (
                        <p className="error">{errors.email}</p>
                    )}

                    <motion.button
                        disabled={isSubmitting}
                        type="submit"
                        whileHover={{ scale: 1.03 }}
                        whileTap={{ scale: 0.99 }}
                    >
                        <span
                            // className='gradient-text-gold'
                            style={{
                                color: '#ccc',
                                fontWeight: '700'
                            }}
                            className="gradient-text"
                        >
                            LEARN MORE
                        </span>{' '}
                        &nbsp;🍷
                    </motion.button>
                </form>
            </div>
        </div>
    );
};
export default OptinForm;
