import React, { useState, useEffect } from 'react';
import { uiState } from '../states/ui-state.js';
import { useSnapshot } from 'valtio';
import { motion } from 'framer-motion';

const Hamburger = () => {
    const [hovered, setHovered] = useState(false);
    const over = (e) => (e.stopPropagation(), setHovered(true));
    const out = () => setHovered(false); //Setup Mouse hover
    useEffect(() => {
        if (hovered) document.body.style.cursor = 'pointer';
        return () => (document.body.style.cursor = 'auto');
    }, [hovered]);
    useSnapshot(uiState);

    return (
        <>
            <div className="hamburger">
                <div
                    style={{
                        position: 'absolute',
                        top: '-.3rem',
                        left: '.72rem'
                    }}
                >
                    <motion.p
                        className="gradient-text"
                        onClick={() => {
                            uiState.showDrawer = true;
                        }}
                        onPointerOver={over}
                        onPointerOut={out}
                        whileHover={{ scale: 1.2 }}
                        whileTap={{ scale: 0.9 }}
                    >
                        <svg
                            viewBox="0 0 100 66"
                            width="26"
                            height="26"
                            fill="#dedede"
                        >
                            <rect width="100" height="11" rx="8"></rect>
                            <rect y="30" width="100" height="11" rx="8"></rect>
                            <rect y="60" width="100" height="11" rx="8"></rect>
                        </svg>
                    </motion.p>
                </div>
            </div>
        </>
    );
};

export default Hamburger;
