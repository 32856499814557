// import { Perf } from 'r3f-perf';
import { StrictMode } from 'react';
import { Suspense, useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom/client';
import { Canvas } from '@react-three/fiber';
import { ThirdwebProvider } from '@thirdweb-dev/react';
import { MapControls, AdaptiveEvents, AdaptiveDpr } from '@react-three/drei';
import { BrowserRouter, useSearchParams } from 'react-router-dom';
import Experience from './components/Experience';
import OptinForm from './components/OptinForm';
import HomeUi from './components/HomeUi';
import Footer from './components/Footer';
import Header from './components/Header';
import Drawer from './components/Drawer';
import Hamburger from './components/Hamburger';
import CookiePop from './components/CookiePop';
import { SparklesComponent } from './components/Sparkles';
import { CookiesProvider } from 'react-cookie';
import { useCookies } from 'react-cookie';
import './style.css';
import './loader.css';
import { uiState } from './states/ui-state';
import { useSnapshot } from 'valtio';
import MintModal from './components/MintModal';
import VideoJS from './components/VideoJS';

const root = ReactDOM.createRoot(document.querySelector('#root'));

function Loader() {
    return (
        <div id="preloader">
            <div id="loader"></div>
            <div id="loader-logo">
                <img width="66px" src="/images/nft-logo.png"></img>{' '}
            </div>
        </div>
    );
}

// const activeChain = 'mumbai';
const activeChain = 'ethereum';

function App() {
    const playerRef = useRef(null);
    const [objSearchParams, setSearchParams] = useSearchParams();
    const [cookies, setCookie] = useCookies(['aff']);

    const snap = useSnapshot(uiState);

    const [studioProps, setStudioProps] = useState(null);
    // set defaults for these props, they are passed into the studio UI to be set
    const [modalProps, setModalProps] = useState({
        opacity: 1,
        label: 'LEARN MORE'
    });

    useEffect(() => {
        if (!studioProps) return;
        const unsubscribe = studioProps.onValuesChange((objValues) => {
            setModalProps({ ...modalProps, ...objValues });
        });
        return unsubscribe;
    }, [studioProps]);

    // one time load on page start for cookies
    useEffect(() => {
        if (cookies && objSearchParams) {
            //look at cookie already set
            //console.log(cookies['aff']);
            const strAffiliate = objSearchParams.get('a');
            if (!cookies.aff || cookies.aff == 'null') {
                setCookie('aff', strAffiliate, {
                    maxAge: 90 * 86400,
                    secure: true,
                    sameSite: true
                });
            }
        }
    }, [objSearchParams, cookies]);

    // const videoUrl1 = 'https://d3gwu7zyyi9vd0.cloudfront.net/wine-inglass.mp4';
    // const videoUrl2 = 'https://d3gwu7zyyi9vd0.cloudfront.net/winepourslow.mp4';
    const documetaryVideo =
        'https://d3gwu7zyyi9vd0.cloudfront.net/6min-watermark.mp4';
    // const videoUrl2 = './videos/wine-inglass.mp4';
    // const videoUrl1 = './videos/winepourslow-small.mp4';

    const videoJsOptions = {
        autoplay: false,
        controls: true,
        responsive: true,
        fluid: true,
        sources: [
            {
                src: documetaryVideo,
                type: 'video/mp4'
            }
        ]
    };

    const handlePlayerReady = (player) => {
        playerRef.current = player;

        // You can handle player events here, for example:
        player.on('waiting', () => {
            // videojs.log('player is waiting');
        });

        player.on('dispose', () => {
            // videojs.log('player will dispose');
        });
    };

    return (
        <StrictMode>
            <CookiesProvider>
                <Suspense fallback={<Loader />}>
                    <Header />
                    <OptinForm />
                    <HomeUi {...modalProps} />
                    <Drawer />
                    <Hamburger />
                    <CookiePop />
                    <MintModal />
                    <VideoJS
                        options={videoJsOptions}
                        onReady={handlePlayerReady}
                    />
                    <Canvas
                        id="canvas-container"
                        gl={{ preserveDrawingBuffer: true }}
                    >
                        <AdaptiveDpr pixelated />
                        <AdaptiveEvents />
                        {/* <Perf position="bottom-left" /> */}
                        <SparklesComponent />
                        <MapControls
                            enabled={snap.mapControls}
                            enableZoom={false}
                        />
                        {/* <ScrollControls pages={5} enabled={snap.scrollControls} > */}
                        <Experience setProps={setStudioProps} />
                        {/* </ScrollControls> */}
                    </Canvas>
                    <Footer />
                </Suspense>
            </CookiesProvider>
        </StrictMode>
    );
}

root.render(
    <ThirdwebProvider activeChain={activeChain}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </ThirdwebProvider>
);
