import { useState, useEffect } from 'react';
import {
    Sparkles,
    Text3D,
    useMatcapTexture,
    Image,
    RoundedBox,
    Float,
    Sphere
} from '@react-three/drei';
import { uiState } from '../states/ui-state';
import { useSnapshot } from 'valtio';
import { editable as e } from '@theatre/r3f';
import { NftBottle } from './NftBottle';
import { Logo } from './Logo';
import { useNavigate } from 'react-router-dom';
import { SparklesComponent } from './Sparkles';

export function About(props) {
    const snap = useSnapshot(uiState);
    const [hovered, setHovered] = useState(false);
    const over = (e) => (e.stopPropagation(), setHovered(true));
    const out = () => setHovered(false);
    const openInNewTab = (url) => {
        window.open(url, '_blank', 'noreferrer');
    };

    const navigate = useNavigate();

    //Setup Mouse hover
    useEffect(() => {
        if (hovered) document.body.style.cursor = 'pointer';
        return () => (document.body.style.cursor = 'auto');
    }, [hovered]);

    const handleClick = (e, state) => {
        e.stopPropagation();
        if (props.target === 'url') {
            openInNewTab(props.url);
        }
        navigate(state);
        uiState.clickedState = true;
    };

    const bottlePos = { x: -0.076, y: 0.19, z: 0.031 };
    const bottleRot = { x: -1.76, y: -0.42, z: -0.17 };

    //TODO this uses 3rd party CDN for this type of texture, fix it for production
    const [matcapTextureGold] = useMatcapTexture(
        '71623B_ECDE8C_30250A_ABA69A',
        64
    );
    const [matcapTextureGlass] = useMatcapTexture(
        '293534_B2BFC5_738289_8A9AA7',
        64
    );
    const [matcapTextureWineRed] = useMatcapTexture(
        '430404_BD9295_7E1E21_94544C',
        64
    );
    const [matcapTextureChrome] = useMatcapTexture(
        '736655_D9D8D5_2F281F_B1AEAB',
        64
    );

    const logoFloatSpeed = 1.5;

    return (
        <>
            {/* Background Image */}
            <group
                // theatreKey="About Image"
                scale={[1, 1.0, 1.0]}
                position={[0, 0.051, -0.14]}
                rotation={[-1.57, 0, 0]}
            >
                <Image
                    scale={[0.66, 1, 1]}
                    url="/images/about.png"
                    transparent
                    receiveShadow={true}
                />
            </group>
            <e.group
                theatreKey={'About / Text1'}
                position={[-0.171, 0.12, -0.392]}
                rotation={[-1.43, 0, 0]}
            >
                <Text3D
                    font="./fonts/Montserrat_Thin_Regular.json"
                    size={0.04}
                    height={0.05}
                >
                    NFT WINE CLUB
                    <meshMatcapMaterial matcap={matcapTextureGold} />
                </Text3D>
            </e.group>
            <Float
                speed={1.5} // Animation speed, defaults to 1
                rotationIntensity={0.5} // XYZ rotation intensity, defaults to 1
                floatIntensity={0.04} // Up/down float intensity, works like a multiplier with floatingRange,defaults to 1
                floatingRange={[0, 0.6]}
            >
                <e.group
                    theatreKey={props.theatreID + ' Bottle'}
                    scale={0.1}
                    position={[bottlePos.x, bottlePos.y, bottlePos.z]}
                    rotation={[bottleRot.x, bottleRot.y, bottleRot.z]}
                >
                    <NftBottle
                        scale={0.1}
                        position={bottlePos}
                        rotation={bottleRot}
                    />
                </e.group>
            </Float>
            <group
                // theatreKey="About Sparkles"
                position={[0, 0.083, -0.17]}
            >
                <SparklesComponent />
            </group>
            <e.group
                theatreKey={props.theatreID + ' Logo'}
                position={[-0.25, 0.18, -0.38]}
                rotation={[-1.52, 0, 0]}
                scale={[1.7, 1.7, 3.3]}
            >
                <Logo />
            </e.group>

            {/* <e.group theatreKey="About BG"> */}
            <group>
                <RoundedBox
                    castShadow={true}
                    receiveShadow={true}
                    args={[1, 0.1, 1]}
                    scale={[0.71, 1.0, 1.07]}
                    position={[0, 0, -0.142]}
                    radius={0.026}
                    smoothness={16}
                >
                    <meshMatcapMaterial matcap={matcapTextureGlass} />
                </RoundedBox>
            </group>
            <e.group
                theatreKey={props.theatreID + ' / L Button'}
                scale={[1, 1, 1]}
                position={[0, 0, 0]}
                rotation={[0, 0, 0]}
                color={'red'}
                onClick={(e) => handleClick(e, 'matt')}
                onPointerOver={over}
                onPointerOut={out}
            >
                <e.group
                    theatreKey={props.theatreID + ' / L Button_Label'}
                    position={[-0.035, 0, 0.017]}
                    rotation={[-1.57, 0, 0]}
                >
                    <Text3D
                        font="./fonts/Montserrat_SemiBold_Regular.json"
                        size={0.035}
                        height={0.03}
                    >
                        {props.text}
                        <meshMatcapMaterial matcap={matcapTextureGold} />
                    </Text3D>
                </e.group>
                <RoundedBox
                    args={[0.3, 0.03, 0.1]}
                    radius={0.01}
                    smoothness={8}
                    castShadow={true}
                    receiveShadow={true}
                >
                    <meshMatcapMaterial matcap={matcapTextureWineRed} />
                </RoundedBox>
            </e.group>
            <e.group
                theatreKey={props.theatreID + ' / R Button'}
                scale={[1, 1, 1]}
                position={[0, 0, 0]}
                rotation={[0, 0, 0]}
                onPointerOver={over}
                onPointerOut={out}
            >
                <e.group
                    theatreKey={props.theatreID + ' / R Button_Label'}
                    position={[-0.032, 0, 0.02]}
                    rotation={[-1.57, 0, 0]}
                >
                    <Text3D
                        font="./fonts/Montserrat_SemiBold_Regular.json"
                        size={0.035}
                        height={0.03}
                    >
                        {'CONTACT'}
                        <meshMatcapMaterial matcap={matcapTextureGold} />
                    </Text3D>
                </e.group>
                <RoundedBox
                    args={[0.3, 0.03, 0.1]}
                    radius={0.01}
                    smoothness={8}
                    castShadow={true}
                    receiveShadow={true}
                    onClick={(e) => handleClick(e, 'contact')}
                >
                    <meshMatcapMaterial matcap={matcapTextureWineRed} />
                </RoundedBox>
            </e.group>
        </>
    );
}
