import React from 'react';
import { uiState } from '../states/ui-state.js';
import { useSnapshot } from 'valtio';
import { useNFT, ThirdwebNftMedia } from '@thirdweb-dev/react';

const ThreeDNftRenderer = (props) => {
    const snap = useSnapshot(uiState);
    const {
        data: nft,
        isLoading: isReadingNft,
        error
    } = useNFT(props.contract, props.nftID);

    return (
        <>
            {isReadingNft ? (
                <div
                    className="gradient-text"
                    style={{
                        color: '#d1ad38',
                        fontWeight: '600',
                        fontSize: '1.6rem',
                        textAlign: 'center',
                        position: 'relative',
                        top: '0px',
                        left: '0px'
                    }}
                >
                    LOADING NEW NFT...
                </div>
            ) : (
                <div className="nft-container">
                    <ThirdwebNftMedia
                        metadata={nft.metadata}
                        key={nft.metadata.id}
                        width={'400px'}
                        height={'400px'}
                    />
                </div>
            )}
        </>
    );
};

export default ThreeDNftRenderer;
