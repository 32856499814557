import { useRef, useState, useEffect } from 'react';
import {
    Environment,
    Float,
    ContactShadows,
    Text3D,
    PerspectiveCamera,
    useMatcapTexture,
    useTexture,
    useScroll
} from '@react-three/drei';
import { useFrame, useThree } from '@react-three/fiber';
import { Logo } from './Logo';
import { About } from './About';
import { Team } from './Team';
import { NftBottle } from './NftBottle';
import { NftScene } from './NftScene';
import { uiState } from '../states/ui-state';
import { useSnapshot } from 'valtio';
import AnimController from './AnimController';
import CustomLabelUi from './CustomLabel/CustomLabelUi';
import { PageCard } from './PageCard';
import { EquirectangularReflectionMapping } from 'three';

//TheatreJS Animation UI
import { getProject, val, types } from '@theatre/core';
import studio from '@theatre/studio';
import extension from '@theatre/r3f/dist/extension';
import { editable as e, SheetProvider } from '@theatre/r3f';
import wineProjectState from '../states/state.json';
import { ThreeDButton } from './ThreeDButton';
import VideoTexture from './VideoTexture';

const project = getProject('Wine Project', {
    state: wineProjectState
});
const wineSheet1 = project.sheet('Wine Sheet');
// const buttonSheet = project.sheet('Button Sheet');
// const scrollSheet = project.sheet('Scroll Sheet');

if (process.env.NODE_ENV === 'development') {
    studio.initialize();
    studio.extend(extension);
    studio.ui.hide(false);
}

const EditableCamera = e(PerspectiveCamera, 'perspectiveCamera');
const EditableScrollCamera = e(PerspectiveCamera, 'perspectiveCamera');

function iOS() {
    return (
        [
            'iPad Simulator',
            'iPhone Simulator',
            'iPod Simulator',
            'iPad',
            'iPhone',
            'iPod'
        ].includes(navigator.platform) ||
        // iPad on iOS 13 detection
        (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
    );
}

export default function Experience(props) {
    const snap = useSnapshot(uiState);
    const [hovered] = useState(false);

    const lookAtRef = useRef();
    const lookAtRefScroll = useRef();

    const initCamPosition = { x: -0.47, y: 0.78, z: 3.33 };
    const initCamRotation = { x: -0.61, y: -0.35, z: -0.15 };
    const nftBottlePos = { x: -0.38, y: -4.36, z: 0.01 };
    const nftBottleRot = { x: -0.7, y: 0.33, z: 0 };
    const bottle1Scale = 0.11;
    const bottle1Pos = { x: 0.39, y: 1.392, z: 0.142 };
    const bottle1Rot = { x: 0.82, y: 0.06, z: -0.09 };
    const bottleFloatSpeed = 1.5;

    //Setup Mouse hover
    useEffect(() => {
        if (hovered) document.body.style.cursor = 'pointer';
        return () => (document.body.style.cursor = 'auto');
    }, [hovered]);

    AnimController();

    //TODO this uses 3rd party CDN for this type of texture, fix it for production
    const [matcapTextureGold] = useMatcapTexture(
        '71623B_ECDE8C_30250A_ABA69A',
        64
    );
    const [matcapTextureGlass] = useMatcapTexture(
        '293534_B2BFC5_738289_8A9AA7',
        64
    );

    const [matcapTextureChrome] = useMatcapTexture(
        '736655_D9D8D5_2F281F_B1AEAB',
        64
    );

    const scroll = useScroll();
    //camera target to look at - Animate in TheatreJS
    useFrame((state, delta) => {
        state.camera.lookAt(lookAtRef.current.position);
        state.camera.updateProjectionMatrix();
        // scrollSheet.sequence.position = 10 * scroll.offset;
        // scrollSheet.sequence.position =
        //     val(scrollSheet.sequence.pointer.length) * scroll.offset;
        return null;
    });

    //----====|| CONNECTING STUDIO PROPS TO OBJECTS ||====----\\
    // need one of these for each object
    // const [r3fHomeProps, setR3fHomeProps] = useState(null);
    const [r3fLogoProps, setR3fLogoProps] = useState(null);
    // this state will get re-used for all objects + props
    // we could make the defaults a little better structured than individual constants in the file but not critical
    const [theaterProps, setTheaterProps] = useState({
        logoFloatSpeed: 1
    });

    useEffect(() => {
        if (!r3fLogoProps) return;
        const unsubscribe = r3fLogoProps.onValuesChange((objValues) => {
            setTheaterProps({ ...theaterProps, ...objValues });
        });
        return unsubscribe;
    }, [r3fLogoProps]);

    const { size } = useThree();
    useEffect(() => {
        uiState.isMobile = size.width < 550;
    }, []);

    //----================================================----\\

    const panoramaTexture = useTexture('./textures/0207_Garden.jpg');
    panoramaTexture.mapping = EquirectangularReflectionMapping;

    // const videoUrl1 =
    //     'https://d3gwu7zyyi9vd0.cloudfront.net/winepourslow-small.mp4';
    // const videoUrl2 =
    //     'https://d3gwu7zyyi9vd0.cloudfront.net/wine-fireplace-small.mp4';
    // const videoUrl3 =
    //     'https://d3gwu7zyyi9vd0.cloudfront.net/drone-sunset-vineyard.mp4';
    const videoUrl1 = './videos/winepourslow-small.mp4';
    const videoUrl2 = './videos/wine-fireplace-small.mp4';
    const videoUrl3 = './videos/drone-sunset-vineyard.mp4';

    return (
        <>
            <SheetProvider sheet={wineSheet1}>
                {/* <SheetProvider sheet={scrollSheet}>
                    <EditableScrollCamera
                        theatreKey="Scroll Camera"
                        fov={50}
                        near={0.1}
                        far={88}
                        zoom={1.0}
                    />
                    <e.group
                        theatreKey="lookAtRefScroll"
                        ref={lookAtRefScroll}
                    ></e.group>
                    <e.group theatreKey="bottle">
                        <NftBottle
                            position={nftBottlePos}
                            rotation={nftBottleRot}
                        />
                    </e.group>
                </SheetProvider> */}

                {/* Contact page video */}
                <e.group theatreKey={'Video1'}>
                    <VideoTexture url={videoUrl1} />
                </e.group>
                {/* About page video */}
                <e.group theatreKey={'Video2'}>
                    <VideoTexture url={videoUrl2} />
                </e.group>

                <e.group
                    theatreKey={'HomeModal'}
                    additionalProps={{
                        opacity: 1,
                        label: "I'M OVER 21 YEARS OLD"
                    }}
                    objRef={props.setProps}
                />
                <Environment map={panoramaTexture} />
                <EditableCamera
                    theatreKey="Camera"
                    makeDefault
                    fov={50}
                    near={0.1}
                    far={500}
                    zoom={1.0}
                    position={[
                        initCamPosition.x,
                        initCamPosition.y,
                        initCamPosition.z
                    ]}
                    rotation={[
                        initCamRotation.x,
                        initCamRotation.y,
                        initCamRotation.z
                    ]}
                />
                <e.group theatreKey="lookAtRef" ref={lookAtRef}></e.group>
                <e.group
                    theatreKey="Logo"
                    additionalProps={{
                        logoFloatSpeed: 1
                    }}
                    objRef={setR3fLogoProps}
                >
                    <e.group theatreKey="Logo / Logo">
                        <Logo />
                    </e.group>
                    <e.group theatreKey="Logo / Text">
                        <Text3D
                            font="./fonts/Montserrat_Thin_Regular.json"
                            size={0.25}
                            height={0.31}
                        >
                            NFT WINE CLUB
                            <meshMatcapMaterial matcap={matcapTextureGold} />
                        </Text3D>
                    </e.group>
                </e.group>
                <Float
                    speed={bottleFloatSpeed} // Animation speed, defaults to 1
                    rotationIntensity={0.1} // XYZ rotation intensity, defaults to 1
                    floatIntensity={0.02} // Up/down float intensity, works like a multiplier with floatingRange,defaults to 1
                    floatingRange={[0, 0.5]}
                >
                    <e.group
                        theatreKey="bottle1"
                        scale={bottle1Scale}
                        position={[bottle1Pos.x, bottle1Pos.y, bottle1Pos.z]}
                        rotation={[bottle1Rot.x, bottle1Rot.y, bottle1Rot.z]}
                    >
                        <NftBottle
                            scale={bottle1Scale}
                            position={bottle1Pos}
                            rotation={bottle1Rot}
                        />
                    </e.group>
                </Float>
                <Float
                    speed={bottleFloatSpeed} // Animation speed, defaults to 1
                    rotationIntensity={0.1} // XYZ rotation intensity, defaults to 1
                    floatIntensity={0.02} // Up/down float intensity, works like a multiplier with floatingRange,defaults to 1
                    floatingRange={[0, 0.5]}
                >
                    <e.group theatreKey="nft-bottle">
                        <NftBottle
                            position={nftBottlePos}
                            rotation={nftBottleRot}
                        />
                    </e.group>
                </Float>
                <e.group theatreKey="Label / Page">
                    <PageCard
                        theatreID="Label"
                        text1="WINE PROGRAM"
                        text2="PRIVATE WHITE LABEL"
                        image="labelpage.png"
                        buttonLabelL="UPLOAD IMAGE"
                        leftTarget="filePicker"
                        buttonLabelR="NEXT"
                        rightTarget="order"
                        rightUrl=""
                        lftButtonWidth=".40"
                        rtButtonWidth=".40"
                        lftButtonFontSize=".028"
                        rtButtonFontSize=".03"
                    >
                        <CustomLabelUi />
                        {/* <e.group theatreKey="Label / CustomUI"></e.group> */}
                    </PageCard>
                </e.group>
                <e.group theatreKey="Wine / Page">
                    <PageCard
                        theatreID="Wine"
                        text1="PREMIUM WINE"
                        text2="DIRECT TO YOU"
                        image="winepage.png"
                        buttonLabelR="JOIN THE CLUB"
                        rightTarget="url"
                        rightUrl="https://cellar.nftwineclub.com/2bottles"
                        lftButtonWidth=".44"
                        rtButtonWidth=".44"
                        rtButtonFontSize=".03"
                        lftButtonFontSize=".03"
                        video={videoUrl3}
                    />
                </e.group>
                <e.group theatreKey="Thank_You / Page">
                    <PageCard
                        theatreID="Thank_You"
                        text1="THANK YOU!"
                        text2="CHECK YOUR INBOX"
                        image="thankyou.png"
                        buttonLabelL="AR EXPERIENCE"
                        buttonLabelR="ABOUT THE WINE"
                        leftTarget="ar"
                        rightTarget="wine"
                        lftButtonWidth=".55"
                        rtButtonWidth=".55"
                        lftButtonFontSize=".035"
                        rtButtonFontSize=".035"
                    />
                </e.group>
                <e.group theatreKey="AR / Page">
                    <PageCard
                        theatreID="AR"
                        text1="AUGMENTED"
                        text2="REALITY"
                        image="ar.png"
                        buttonLabelL="BACK"
                        buttonLabelR="ABOUT THE WINE"
                        leftTarget="front"
                        rightTarget="wine"
                        lftButtonWidth="0"
                        rtButtonWidth=".55"
                        lftButtonFontSize=".035"
                        rtButtonFontSize=".035"
                    />
                </e.group>
                {/* <PresentationControls
                    polar={[-0.4, 0.2]}
                    azimuth={[-1, 0.75]}
                    config={{ mass: 3, tension: 222 }}
                    snap={{ mass: 3, tension: 222 }}
                > */}
                <e.group theatreKey="About / Holder">
                    <About theatreID="About" text="THE TEAM" />
                </e.group>
                <e.group theatreKey="Team / Holder_1">
                    <Team
                        name="THE REVEREND"
                        title="CEO/CTO"
                        image="matt.png"
                        theatreID="Matt"
                    />
                </e.group>
                <e.group theatreKey="Team / Holder_2">
                    <Team
                        name="BRETT HUDSON"
                        title="PRESIDENT"
                        image="brett.png"
                        theatreID="Brett"
                    />
                </e.group>
                <e.group theatreKey="Team / Holder_3">
                    <Team
                        name="DAVID HARMON"
                        title="CHIEF WINE OFFICER"
                        image="david.png"
                        theatreID="David"
                    />
                </e.group>
                <e.group theatreKey="Team / Holder_4">
                    <Team
                        name="NICO MILLIGAN"
                        title="COO"
                        image="nico.png"
                        theatreID="Nico"
                    />
                </e.group>
                <e.group theatreKey="Team / Holder_5">
                    <Team
                        name="BOZANA CAVAR"
                        title="BRAND AMBASSADOR"
                        image="bozana.png"
                        theatreID="Bozana"
                    />
                </e.group>
                <e.group theatreKey="Team / Holder_6">
                    <Team
                        name="LEE HENDELSON"
                        title="CFO"
                        image="lee.png"
                        theatreID="Lee"
                    />
                </e.group>
                <e.group theatreKey="Contact / Holder">
                    <Team
                        name="CONTACT US"
                        title="LET'S CHAT"
                        image="contact.png"
                        theatreID="Contact"
                        imageLink="https://discord.com/invite/QVWeDJcBah"
                    />
                </e.group>
                {/* <PresentationControls
                    // polar={[-0.4, 0.2]}
                    // azimuth={[-1, 0.75]}
                    // // global={true}
                    // config={{ mass: 1, tension: 170, friction: 26 }}
                    snap={{ mass: 1, tension: 170, friction: 26 }}
                    enabled={uiState.nftControls}
                > */}
                <e.group theatreKey="NFT SCENE">
                    <NftScene theatreID="NFT Scene" />
                </e.group>
                <e.group theatreKey="Order-Button">
                    <ThreeDButton
                        theatreID="Order"
                        label="ORDER"
                        fontSize=".035"
                        baseWidth=".33"
                        url="https://www.whitelabelwineclub.com/"
                    />
                </e.group>
                {/* </PresentationControls> */}
                {/* 4 */}
            </SheetProvider>
        </>
    );
}
