import { Sparkles } from '@react-three/drei';
import { useMemo, Suspense } from 'react';

export const SparklesComponent = () => {
    const randomValue = useMemo(() => {
        return [Math.random(), Math.random(), Math.random()];
    }, []);
    return (
        <>
            <Sparkles
                count={4}
                speed={0.3}
                size={randomValue[0] * (4 - 1) + 1}
                noise={1}
                position={[0, 0.083, -0.17]}
            />
            <Sparkles
                count={3}
                speed={0.1}
                size={randomValue[1] * (2 - 1) + 1}
                noise={0.5}
                color={'#fcfaa7'}
                position={[0, 0.083, -0.17]}
            />
            <Sparkles
                count={3}
                speed={0.1}
                size={randomValue[2] * (2 - 1) + 1}
                noise={0.1}
                color={'#ffc505'}
                position={[0, 0.083, -0.17]}
            />
        </>
    );
};
