import { useState, useEffect } from 'react';
import {
    Text3D,
    useMatcapTexture,
    Image,
    Float,
    Html,
    RoundedBox
} from '@react-three/drei';
import { editable as e } from '@theatre/r3f';
import { Logo } from './Logo';
import { NftBottle } from './NftBottle';
import { uiState } from '../states/ui-state';
import { useSnapshot } from 'valtio';
import { useNavigate } from 'react-router-dom';
import { SparklesComponent } from './Sparkles';

export function Team(props) {
    const bottlePos = { x: -0.076, y: 0.19, z: 0.031 };
    const bottleRot = { x: -1.76, y: -0.42, z: -0.17 };
    const bottleScale = 0.09;

    const snap = useSnapshot(uiState);
    const [hovered, setHovered] = useState(false);
    const over = (e) => (e.stopPropagation(), setHovered(true));
    const out = () => setHovered(false);

    //Setup Mouse hover
    useEffect(() => {
        if (hovered) document.body.style.cursor = 'pointer';
        return () => (document.body.style.cursor = 'auto');
    }, [hovered]);

    const navigate = useNavigate();

    const handleClick = (e, state) => {
        e.stopPropagation();
        if (props.target === 'url') {
            openInNewTab(props.url);
        }
        navigate(state);
    };

    const openInNewTab = (url) => {
        window.open(url, '_blank', 'noreferrer');
    };

    //TODO this uses 3rd party CDN for this type of texture, fix it for production
    const [matcapTextureGold] = useMatcapTexture(
        '71623B_ECDE8C_30250A_ABA69A',
        64
    );
    const [matcapTextureGlass] = useMatcapTexture(
        '293534_B2BFC5_738289_8A9AA7',
        64
    );
    const [matcapTextureWineRed] = useMatcapTexture(
        '430404_BD9295_7E1E21_94544C',
        64
    );
    const [matcapTextureChrome] = useMatcapTexture(
        '736655_D9D8D5_2F281F_B1AEAB',
        64
    );

    const imagePath = '/images/' + props.image;

    return (
        <>
            <e.group
                theatreKey={props.theatreID + ' / Sparkles'}
                position={[0, 0.083, -0.17]}
            >
                <SparklesComponent />
            </e.group>
            <e.group
                theatreKey={props.theatreID + ' Logo'}
                position={[-0.25, 0.18, -0.38]}
                rotation={[-1.52, 0, 0]}
                scale={[1.7, 1.7, 3.3]}
            >
                <Logo />
            </e.group>

            {props.imageLink && (
                <>
                    <group
                        // theatreKey={props.theatreID + ' Image'}
                        scale={[1, 1, 1]}
                        position={[0, 0.065, 0.02]}
                        rotation={[-1.571, 0, 0]}
                    >
                        <Image
                            receiveShadow={true}
                            scale={[0.66, 1, 1]}
                            url={imagePath}
                            transparent
                        />
                    </group>
                    {/* Invisible Image to click on */}
                    <e.group
                        theatreKey={props.theatreID + ' / Link Image'}
                        scale={[1.1, 1.1, 1.1]}
                        position={[0, 0.065, 0.077]}
                        rotation={[-1.571, 0, 0]}
                        onPointerOver={over}
                        onPointerOut={out}
                        onClick={() => openInNewTab(props.imageLink)}
                    >
                        <Image
                            scale={[0.603, 1, 1]}
                            url={'/images/click.png'}
                            transparent
                            opacity={0}
                        />
                    </e.group>
                </>
            )}
            {!props.imageLink && (
                <group
                    // theatreKey={props.theatreID + ' Image'}
                    scale={[1, 1, 1]}
                    position={[0, 0.065, 0.02]}
                    rotation={[-1.571, 0, 0]}
                >
                    <Image
                        receiveShadow={true}
                        scale={[0.66, 1, 1]}
                        url={imagePath}
                        transparent
                    />
                </group>
            )}
            <e.group
                theatreKey={props.theatreID + ' / Text1'}
                position={[-0.171, 0.12, -0.392]}
                rotation={[-1.43, 0, 0]}
            >
                <Text3D
                    font="./fonts/Montserrat_Thin_Regular.json"
                    size={0.04}
                    height={0.05}
                >
                    {props.name}
                    <meshMatcapMaterial matcap={matcapTextureGold} />
                </Text3D>
            </e.group>
            <e.group
                theatreKey={props.theatreID + ' / Text2'}
                position={[-0.167, 0.115, -0.339]}
                rotation={[-1.43, 0, 0]}
            >
                <Text3D
                    font="./fonts/Montserrat_Thin_Regular.json"
                    size={0.028}
                    height={0.05}
                >
                    {props.title}
                    <meshMatcapMaterial matcap={matcapTextureGold} />
                </Text3D>
            </e.group>
            <Float
                speed={1.5} // Animation speed, defaults to 1
                rotationIntensity={0.6} // XYZ rotation intensity, defaults to 1
                floatIntensity={0.06} // Up/down float intensity, works like a multiplier with floatingRange,defaults to 1
                floatingRange={[0, 0.4]}
            >
                <e.group
                    theatreKey={props.theatreID + ' Bottle'}
                    scale={bottleScale}
                    position={[bottlePos.x, bottlePos.y, bottlePos.z]}
                    rotation={[bottleRot.x, bottleRot.y, bottleRot.z]}
                >
                    <NftBottle
                        scale={bottleScale}
                        position={bottlePos}
                        rotation={bottleRot}
                    />
                </e.group>
            </Float>
            <group
            // theatreKey={props.theatreID + ' BG'}
            >
                <RoundedBox
                    args={[1, 0.1, 1]}
                    scale={[0.73, 1.0, 1.12]}
                    position={[-0.006, 0.013, 0.019]}
                    radius={0.026}
                    smoothness={16}
                    castShadow={true}
                    receiveShadow={true}
                >
                    <meshMatcapMaterial matcap={matcapTextureGlass} />
                </RoundedBox>
            </group>
            <e.group
                theatreKey={props.theatreID + ' / L Button'}
                scale={[1, 1, 1]}
                position={[0, 0, 0]}
                rotation={[0, 0, 0]}
                onPointerOver={over}
                onPointerOut={out}
                onClick={(e) => handleClick(e, uiState.forward)}
            >
                <group
                    theatreKey={props.theatreID + ' / L Button_Label'}
                    position={[-0.035, 0, 0.017]}
                    rotation={[-1.57, 0, 0]}
                >
                    <Text3D
                        font="./fonts/Montserrat_SemiBold_Regular.json"
                        size={0.04}
                        height={0.03}
                    >
                        {'<<'}
                        <meshMatcapMaterial matcap={matcapTextureGold} />
                    </Text3D>
                </group>
                <RoundedBox
                    args={[0.26, 0.03, 0.08]}
                    radius={0.01}
                    smoothness={8}
                    castShadow={true}
                    receiveShadow={true}
                >
                    <meshMatcapMaterial matcap={matcapTextureWineRed} />
                </RoundedBox>
            </e.group>
            <e.group
                theatreKey={props.theatreID + ' / R Button'}
                scale={[1, 1, 1]}
                position={[0, 0, 0]}
                rotation={[0, 0, 0]}
                onPointerOver={over}
                onPointerOut={out}
                onClick={(e) => handleClick(e, uiState.back)}
            >
                <group
                    theatreKey={props.theatreID + ' / R Button_Label'}
                    position={[-0.032, 0, 0.02]}
                    rotation={[-1.57, 0, 0]}
                >
                    <Text3D
                        font="./fonts/Montserrat_SemiBold_Regular.json"
                        size={0.04}
                        height={0.03}
                    >
                        {'>>'}
                        <meshMatcapMaterial matcap={matcapTextureGold} />
                    </Text3D>
                </group>

                <RoundedBox
                    args={[0.26, 0.03, 0.08]}
                    radius={0.01}
                    smoothness={8}
                    castShadow={true}
                    receiveShadow={true}
                >
                    <meshMatcapMaterial matcap={matcapTextureWineRed} />
                </RoundedBox>
            </e.group>
        </>
    );
}
