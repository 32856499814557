import { motion } from 'framer-motion';
import { uiState } from '../../states/ui-state.js';

const Backdrop = (props) => {
    const handleClick = (e, state) => {
        e.stopPropagation();
        uiState.customLabelUIShow = false;
        uiState.homeReveal = false;
        uiState.optinShow = false;
    };

    const clickIt = props.clickActive;
    let cursorStyle = 'pointer';

    clickIt ? (cursorStyle = 'pointer') : (cursorStyle = 'auto');

    return (
        <motion.div
            className={props.cssName}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={(e) => handleClick(e, props.clickTarget)}
            style={{ cursor: clickIt }}
        >
            {props.children}
        </motion.div>
    );
};

export default Backdrop;
