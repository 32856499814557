import { useEffect } from 'react';
import { useSnapshot } from 'valtio';
import { uiState, videoPlayerProxy } from '../../states/ui-state';
import { labelState } from '../../states/labelState';
import { useLocation, useNavigate } from 'react-router-dom';
//TheatreJS Animation UI
import { getProject } from '@theatre/core';
import wineProjectState from '../../states/state.json';

const project = getProject('Wine Project', {
    state: wineProjectState
});
const wineSheet1 = project.sheet('Wine Sheet');

const AnimController = (props) => {
    const snap = useSnapshot(uiState);
    const location = useLocation();
    const navigate = useNavigate();

    //show hidden UI on load after Suspense
    useEffect(() => {
        uiState.homeShow = true;
        uiState.footerShow = true;
        //TODO logic if not cookie
        uiState.cookiePopShow = true;
    }, []);

    // UI Animation States
    useEffect(() => {
        console.log(
            'Anim Location:',
            uiState.animLocation,
            '\n',
            ':',
            location.pathname
        );
        uiState.homeShow = false;
        uiState.optinShow = false;
        uiState.customLabelUIShow = false;
        uiState.videoPlayerShow = false;
        uiState.scrollControls = true;
        uiState.animEndUpdate = false;
        wineSheet1.project.ready.then(() => {
            const path = location.pathname.replace('/', '');
            if (
                path != uiState.animLocation ||
                (path == 'nft' &&
                    uiState.animLocation == 'nft' &&
                    uiState.nftSceneActive)
            ) {
                uiState.animSpeed = 1;
                uiState.animLoop = 1;
                switch (location.pathname) {
                    case '/':
                        switch (uiState.animLocation) {
                            case '/':
                                uiState.homeShow = true;
                                uiState.mapControls = false;
                                return;
                            case 'front':
                                uiState.animStart = 0;
                                uiState.animEnd = 4;
                                uiState.animDirection = 'reverse';
                                uiState.animSpeed = 3;
                                break;
                            case 'about':
                                uiState.animStart = 6.0;
                                uiState.animEnd = 10;
                                uiState.animDirection = 'normal';
                                uiState.animSpeed = 2;
                                break;
                            case 'create':
                                uiState.animStart = 108.0;
                                uiState.animEnd = 111.0;
                                uiState.animDirection = 'normal';
                                uiState.animSpeed = 1.0;
                                break;
                            case 'order':
                                uiState.animStart = 176.0;
                                uiState.animEnd = 177.0;
                                uiState.animDirection = 'normal';
                                uiState.animSpeed = 0.5;
                                break;
                            case 'wine':
                                uiState.animStart = 128.0;
                                uiState.animEnd = 130.0;
                                uiState.animDirection = 'normal';
                                uiState.animSpeed = 0.75;
                                break;
                            case 'thankyou':
                                uiState.animStart = 70.0;
                                uiState.animEnd = 71.0;
                                uiState.animDirection = 'normal';
                                uiState.animSpeed = 0.5;
                                break;
                            case 'matt':
                                uiState.animStart = 30.0;
                                uiState.animEnd = 31.0;
                                uiState.animDirection = 'normal';
                                uiState.animSpeed = 0.25;
                                break;
                            case 'brett':
                                uiState.animStart = 154;
                                uiState.animEnd = 155;
                                uiState.animDirection = 'reverse';
                                uiState.animSpeed = 0.5;
                                break;
                            case 'david':
                                uiState.animStart = 167.0;
                                uiState.animEnd = 168.0;
                                uiState.animDirection = 'reverse';
                                uiState.animSpeed = 0.5;
                                break;
                            case 'nico':
                                uiState.animStart = 212.0;
                                uiState.animEnd = 213.0;
                                uiState.animDirection = 'reverse';
                                uiState.animSpeed = 0.5;
                                break;
                            case 'bozana':
                                uiState.animStart = 210.0;
                                uiState.animEnd = 211.0;
                                uiState.animDirection = 'reverse';
                                uiState.animSpeed = 0.5;
                                break;
                            case 'lee':
                                uiState.animStart = 208.0;
                                uiState.animEnd = 209.0;
                                uiState.animDirection = 'reverse';
                                uiState.animSpeed = 0.5;
                                break;
                            case 'ar':
                                uiState.animStart = 94.0;
                                uiState.animEnd = 95;
                                uiState.animDirection = 'normal';
                                uiState.animSpeed = 0.4;
                                break;
                            case 'nft':
                                uiState.animStart = 104.0;
                                uiState.animEnd = 105;
                                uiState.animDirection = 'normal';
                                uiState.animSpeed = 0.3;
                                break;
                            case 'contact':
                                uiState.animStart = 139.0;
                                uiState.animEnd = 140;
                                uiState.animDirection = 'normal';
                                uiState.animSpeed = 0.5;
                                break;
                            default:
                                break;
                        }
                        uiState.animLocation = '/';
                        break;
                    case '/front':
                        switch (uiState.animLocation) {
                            case '/':
                                uiState.animStart = 0;
                                uiState.animEnd = 4;
                                uiState.animDirection = 'normal';
                                uiState.animSpeed = 1.0;
                                break;
                            case 'about':
                                uiState.animStart = 4;
                                uiState.animEnd = 6.0;
                                uiState.animDirection = 'reverse';
                                break;
                            case 'create':
                                uiState.animStart = 114.0;
                                uiState.animEnd = 116;
                                uiState.animDirection = 'normal';
                                uiState.animSpeed = 0.75;
                                break;
                            case 'order':
                                uiState.animStart = 12.0;
                                uiState.animEnd = 14;
                                uiState.animDirection = 'normal';
                                break;
                            case 'wine':
                                uiState.animStart = 122;
                                uiState.animEnd = 124;
                                uiState.animDirection = 'normal';
                                uiState.animSpeed = 0.75;
                                break;
                            case 'matt':
                                uiState.animStart = 29.0;
                                uiState.animEnd = 30.0;
                                uiState.animDirection = 'reverse';
                                uiState.animSpeed = 0.5;
                                break;
                            case 'brett':
                                uiState.animStart = 152;
                                uiState.animEnd = 153;
                                uiState.animDirection = 'normal';
                                uiState.animSpeed = 0.5;
                                break;
                            case 'david':
                                uiState.animStart = 165.0;
                                uiState.animEnd = 166.0;
                                uiState.animDirection = 'normal';
                                uiState.animSpeed = 0.5;
                                break;
                            case 'nico':
                                uiState.animStart = 203.0;
                                uiState.animEnd = 204.0;
                                uiState.animDirection = 'normal';
                                uiState.animSpeed = 0.5;
                                break;
                            case 'bozana':
                                uiState.animStart = 204.0;
                                uiState.animEnd = 205.0;
                                uiState.animDirection = 'reverse';
                                uiState.animSpeed = 0.5;
                                break;
                            case 'lee':
                                uiState.animStart = 206.0;
                                uiState.animEnd = 207.0;
                                uiState.animDirection = 'reverse';
                                uiState.animSpeed = 0.5;
                                break;
                            case 'contact':
                                uiState.animStart = 137;
                                uiState.animEnd = 138;
                                uiState.animDirection = 'normal';
                                uiState.animSpeed = 0.5;
                                break;
                            case 'thankyou':
                                uiState.animStart = 64.0;
                                uiState.animEnd = 65;
                                uiState.animDirection = 'normal';
                                uiState.animSpeed = 0.75;
                                break;
                            case 'ar':
                                uiState.animStart = 187.0;
                                uiState.animEnd = 188;
                                uiState.animDirection = 'reverse';
                                uiState.animSpeed = 0.75;
                                break;
                            case 'nft':
                                uiState.animStart = 101.0;
                                uiState.animEnd = 102;
                                uiState.animDirection = 'reverse';
                                uiState.animSpeed = 0.75;
                                break;
                            default:
                                break;
                        }
                        uiState.animLocation = 'front';
                        break;
                    case '/create':
                        switch (uiState.animLocation) {
                            case '/':
                                uiState.animStart = 114.0;
                                uiState.animEnd = 116;
                                uiState.animDirection = 'reverse';
                                uiState.animSpeed = 0.5;
                                labelState.showUI = true;
                                break;
                            case 'front':
                                uiState.animStart = 114.0;
                                uiState.animEnd = 116;
                                uiState.animDirection = 'reverse';
                                uiState.animSpeed = 0.75;
                                break;
                            case 'about':
                                uiState.animStart = 117.0;
                                uiState.animEnd = 119.0;
                                uiState.animDirection = 'normal';
                                uiState.animSpeed = 1.0;
                                break;
                            case 'wine':
                                uiState.animStart = 119;
                                uiState.animEnd = 122;
                                uiState.animDirection = 'reverse';
                                uiState.animSpeed = 0.75;
                                break;
                            case 'order':
                                uiState.animStart = 112.0;
                                uiState.animEnd = 113;
                                uiState.animDirection = 'reverse';
                                uiState.animSpeed = 0.8;
                                break;
                            case 'thankyou':
                                uiState.animStart = 191.0;
                                uiState.animEnd = 193;
                                uiState.animDirection = 'normal';
                                uiState.animSpeed = 0.75;
                                break;
                            case 'matt':
                                uiState.animStart = 143;
                                uiState.animEnd = 145;
                                uiState.animDirection = 'reverse';
                                uiState.animSpeed = 0.75;
                                break;
                            case 'brett':
                                uiState.animStart = 155;
                                uiState.animEnd = 158;
                                uiState.animDirection = 'normal';
                                uiState.animSpeed = 0.75;
                                break;
                            case 'david':
                                uiState.animStart = 168.0;
                                uiState.animEnd = 170.0;
                                uiState.animDirection = 'normal';
                                uiState.animSpeed = 0.6;
                                break;
                            case 'nico':
                                uiState.animStart = 213.0;
                                uiState.animEnd = 215.0;
                                uiState.animDirection = 'normal';
                                uiState.animSpeed = 0.6;
                                break;
                            case 'bozana':
                                uiState.animStart = 216.0;
                                uiState.animEnd = 218.0;
                                uiState.animDirection = 'normal';
                                uiState.animSpeed = 0.6;
                                break;
                            case 'lee':
                                uiState.animStart = 219.0;
                                uiState.animEnd = 221.0;
                                uiState.animDirection = 'normal';
                                uiState.animSpeed = 0.6;
                                break;
                            case 'contact':
                                uiState.animStart = 141.0;
                                uiState.animEnd = 143;
                                uiState.animDirection = 'normal';
                                uiState.animSpeed = 0.75;
                                break;
                            case 'ar':
                                uiState.animStart = 195.0;
                                uiState.animEnd = 196;
                                uiState.animDirection = 'normal';
                                uiState.animSpeed = 0.4;
                                break;
                            case 'nft':
                                uiState.animStart = 106.0;
                                uiState.animEnd = 108;
                                uiState.animDirection = 'normal';
                                uiState.animSpeed = 0.5;
                                break;
                            default:
                                break;
                        }
                        uiState.animLocation = 'create';
                        uiState.mapControls = false;
                        break;
                    case '/order':
                        switch (uiState.animLocation) {
                            case '/':
                                uiState.animStart = 15.0;
                                uiState.animEnd = 17.0;
                                uiState.animDirection = 'reverse';
                                uiState.animSpeed = 0.75;
                                labelState.showUI = true;
                                break;
                            case 'front':
                                uiState.animStart = 12.0;
                                uiState.animEnd = 14;
                                uiState.animDirection = 'reverse';
                                break;
                            case 'about':
                                uiState.animStart = 174.0;
                                uiState.animEnd = 175.0;
                                uiState.animDirection = 'reverse';
                                break;
                            case 'wine':
                                uiState.animStart = 178;
                                uiState.animEnd = 180;
                                uiState.animDirection = 'normal';
                                uiState.animSpeed = 0.75;
                                break;
                            case 'create':
                                uiState.animStart = 112.0;
                                uiState.animEnd = 113;
                                uiState.animDirection = 'normal';
                                uiState.animSpeed = 0.8;
                                break;
                            case 'david':
                                uiState.animStart = 173.0;
                                uiState.animEnd = 174;
                                uiState.animDirection = 'normal';
                                uiState.animSpeed = 0.4;
                                break;
                            case 'nft':
                                uiState.animStart = 180.0;
                                uiState.animEnd = 181;
                                uiState.animDirection = 'reverse';
                                uiState.animSpeed = 0.5;
                                break;
                            default:
                                break;
                        }
                        uiState.animLocation = 'order';
                        break;
                    case '/wine':
                        switch (uiState.animLocation) {
                            case '/':
                                uiState.animStart = 122;
                                uiState.animEnd = 124;
                                uiState.animDirection = 'reverse';
                                uiState.animSpeed = 0.5;
                                break;
                            case 'about':
                                uiState.animStart = 125.0;
                                uiState.animEnd = 127;
                                uiState.animDirection = 'reverse';
                                uiState.animSpeed = 0.75;
                                break;
                            case 'create':
                                uiState.animStart = 119;
                                uiState.animEnd = 122;
                                uiState.animDirection = 'normal';
                                uiState.animSpeed = 0.75;
                                break;
                            case 'order':
                                uiState.animStart = 178;
                                uiState.animEnd = 180;
                                uiState.animDirection = 'reverse';
                                uiState.animSpeed = 0.75;
                                break;
                            case 'front':
                                uiState.animStart = 122;
                                uiState.animEnd = 124;
                                uiState.animDirection = 'reverse';
                                uiState.animSpeed = 0.75;
                                break;
                            case 'thankyou':
                                uiState.animStart = 189.0;
                                uiState.animEnd = 190.0;
                                uiState.animDirection = 'normal';
                                uiState.animSpeed = 0.5;
                                break;
                            case 'matt':
                                uiState.animStart = 145.0;
                                uiState.animEnd = 146.0;
                                uiState.animDirection = 'normal';
                                uiState.animSpeed = 0.25;
                                break;
                            case 'brett':
                                uiState.animStart = 159;
                                uiState.animEnd = 162;
                                uiState.animDirection = 'normal';
                                uiState.animSpeed = 0.75;
                                break;
                            case 'david':
                                uiState.animStart = 171.0;
                                uiState.animEnd = 173.0;
                                uiState.animDirection = 'reverse';
                                uiState.animSpeed = 0.4;
                                break;
                            case 'nico':
                                uiState.animStart = 228.0;
                                uiState.animEnd = 230.0;
                                uiState.animDirection = 'reverse';
                                uiState.animSpeed = 0.4;
                                break;
                            case 'bozana':
                                uiState.animStart = 225.0;
                                uiState.animEnd = 227.0;
                                uiState.animDirection = 'reverse';
                                uiState.animSpeed = 0.4;
                                break;
                            case 'lee':
                                uiState.animStart = 222.0;
                                uiState.animEnd = 224.0;
                                uiState.animDirection = 'reverse';
                                uiState.animSpeed = 0.4;
                                break;
                            case 'contact':
                                uiState.animStart = 134.0;
                                uiState.animEnd = 136;
                                uiState.animDirection = 'normal';
                                uiState.animSpeed = 0.75;
                                break;
                            case 'wine':
                                uiState.animStart = 185.0;
                                uiState.animEnd = 186;
                                uiState.animDirection = 'reverse';
                                uiState.animSpeed = 0.75;
                                break;
                            case 'nft':
                                uiState.animStart = 131.0;
                                uiState.animEnd = 133;
                                uiState.animDirection = 'reverse';
                                uiState.animSpeed = 0.6;
                                break;
                            case 'ar':
                                uiState.animStart = 185.0;
                                uiState.animEnd = 186;
                                uiState.animDirection = 'normal';
                                uiState.animSpeed = 0.4;
                                break;
                            default:
                                break;
                        }
                        uiState.animLocation = 'wine';
                        break;
                    case '/about':
                        switch (uiState.animLocation) {
                            case '/':
                                uiState.animStart = 4;
                                uiState.animEnd = 6.0;
                                uiState.animDirection = 'normal';
                                uiState.animSpeed = 0.75;
                                break;
                            case 'front':
                                uiState.animStart = 4;
                                uiState.animEnd = 6.0;
                                uiState.animDirection = 'normal';
                                break;
                            case 'create':
                                uiState.animStart = 117.0;
                                uiState.animEnd = 119.0;
                                uiState.animDirection = 'reverse';
                                uiState.animSpeed = 1.0;
                                break;
                            case 'order':
                                uiState.animStart = 174.0;
                                uiState.animEnd = 175.0;
                                uiState.animDirection = 'normal';
                                break;
                            case 'matt':
                                uiState.animStart = 21;
                                uiState.animEnd = 23;
                                uiState.animDirection = 'reverse';
                                uiState.animSpeed = 1.5;
                                break;
                            case 'brett':
                                uiState.animStart = 150;
                                uiState.animEnd = 151;
                                uiState.animDirection = 'normal';
                                uiState.animSpeed = 0.4;
                                break;
                            case 'david':
                                uiState.animStart = 35;
                                uiState.animEnd = 36;
                                uiState.animDirection = 'normal';
                                uiState.animSpeed = 0.5;
                                break;
                            case 'nico':
                                uiState.animStart = 201;
                                uiState.animEnd = 202;
                                uiState.animDirection = 'normal';
                                uiState.animSpeed = 0.5;
                                break;
                            case 'bozana':
                                uiState.animStart = 199;
                                uiState.animEnd = 200;
                                uiState.animDirection = 'normal';
                                uiState.animSpeed = 0.5;
                                break;
                            case 'lee':
                                uiState.animStart = 197;
                                uiState.animEnd = 198;
                                uiState.animDirection = 'normal';
                                uiState.animSpeed = 0.5;
                                break;
                            case 'contact':
                                uiState.animStart = 39;
                                uiState.animEnd = 40;
                                uiState.animDirection = 'reverse';
                                uiState.animSpeed = 0.75;
                                break;
                            case 'wine':
                                uiState.animStart = 125.0;
                                uiState.animEnd = 127;
                                uiState.animDirection = 'normal';
                                uiState.animSpeed = 0.75;
                                break;
                            case 'contact':
                                uiState.animStart = 39;
                                uiState.animEnd = 40;
                                uiState.animDirection = 'reverse';
                                uiState.animSpeed = 0.5;
                                break;
                            case 'thankyou':
                                uiState.animStart = 68.0;
                                uiState.animEnd = 69;
                                uiState.animDirection = 'normal';
                                uiState.animSpeed = 0.5;
                                break;
                            case 'ar':
                                uiState.animStart = 90.0;
                                uiState.animEnd = 91;
                                uiState.animDirection = 'normal';
                                uiState.animSpeed = 0.5;
                                break;
                            case 'nft':
                                uiState.animStart = 102.0;
                                uiState.animEnd = 103;
                                uiState.animDirection = 'normal';
                                uiState.animSpeed = 0.5;
                                break;
                            default:
                                break;
                        }
                        uiState.animLocation = 'about';

                        break;
                    case '/matt':
                        switch (uiState.animLocation) {
                            case '/':
                                uiState.animStart = 30.0;
                                uiState.animEnd = 31.0;
                                uiState.animDirection = 'reverse';
                                uiState.animSpeed = 0.25;
                                break;
                            case 'about':
                                uiState.animStart = 21;
                                uiState.animEnd = 23;
                                uiState.animDirection = 'normal';
                                uiState.animSpeed = 1.5;
                                break;
                            case 'front':
                                uiState.animStart = 29.0;
                                uiState.animEnd = 30.0;
                                uiState.animDirection = 'normal';
                                uiState.animSpeed = 0.5;
                                break;
                            case 'brett':
                                uiState.animStart = 33;
                                uiState.animEnd = 34;
                                uiState.animDirection = 'reverse';
                                uiState.animSpeed = 0.75;
                                break;
                            case 'create':
                                uiState.animStart = 143;
                                uiState.animEnd = 145;
                                uiState.animDirection = 'normal';
                                uiState.animSpeed = 0.75;
                                break;
                            case 'wine':
                                uiState.animStart = 145.0;
                                uiState.animEnd = 146.0;
                                uiState.animDirection = 'reverse';
                                uiState.animSpeed = 0.25;
                                break;
                            case 'nft':
                                uiState.animStart = 147.0;
                                uiState.animEnd = 148.0;
                                uiState.animDirection = 'reverse';
                                uiState.animSpeed = 0.4;
                                break;
                            default:
                                break;
                        }
                        uiState.animLocation = 'matt';
                        uiState.forward = 'brett';
                        uiState.back = 'about';
                        break;
                    case '/brett':
                        switch (uiState.animLocation) {
                            case '/':
                                uiState.animStart = 154;
                                uiState.animEnd = 155;
                                uiState.animDirection = 'normal';
                                uiState.animSpeed = 0.25;
                                break;
                            case 'front':
                                uiState.animStart = 152;
                                uiState.animEnd = 153;
                                uiState.animDirection = 'reverse';
                                uiState.animSpeed = 0.5;
                                break;
                            case 'create':
                                uiState.animStart = 155;
                                uiState.animEnd = 158;
                                uiState.animDirection = 'reverse';
                                uiState.animSpeed = 0.75;
                                break;
                            case 'wine':
                                uiState.animStart = 159;
                                uiState.animEnd = 162;
                                uiState.animDirection = 'reverse';
                                uiState.animSpeed = 0.75;
                                break;
                            case 'about':
                                uiState.animStart = 150;
                                uiState.animEnd = 151;
                                uiState.animDirection = 'reverse';
                                uiState.animSpeed = 0.4;
                                break;
                            case 'matt':
                                uiState.animStart = 33;
                                uiState.animEnd = 34;
                                uiState.animDirection = 'normal';
                                uiState.animSpeed = 0.75;
                                break;
                            case 'david':
                                uiState.animStart = 34;
                                uiState.animEnd = 35;
                                uiState.animDirection = 'reverse';
                                uiState.animSpeed = 0.75;
                                break;
                            case 'nft':
                                uiState.animStart = 163.0;
                                uiState.animEnd = 164.0;
                                uiState.animDirection = 'reverse';
                                uiState.animSpeed = 0.5;
                                break;
                            default:
                                break;
                        }
                        uiState.animLocation = 'brett';
                        uiState.forward = 'david';
                        uiState.back = 'matt';
                        break;
                    case '/david':
                        switch (uiState.animLocation) {
                            case '/':
                                uiState.animStart = 165.0;
                                uiState.animEnd = 166.0;
                                uiState.animDirection = 'reverse';
                                uiState.animSpeed = 0.25;
                                break;
                            case 'front':
                                uiState.animStart = 165.0;
                                uiState.animEnd = 166.0;
                                uiState.animDirection = 'reverse';
                                uiState.animSpeed = 0.5;
                                break;
                            case 'create':
                                uiState.animStart = 168.0;
                                uiState.animEnd = 170.0;
                                uiState.animDirection = 'reverse';
                                uiState.animSpeed = 0.6;
                                break;
                            case 'wine':
                                uiState.animStart = 171.0;
                                uiState.animEnd = 173.0;
                                uiState.animDirection = 'normal';
                                uiState.animSpeed = 0.4;
                                break;
                            case 'brett':
                                uiState.animStart = 34;
                                uiState.animEnd = 35;
                                uiState.animDirection = 'normal';
                                uiState.animSpeed = 0.75;
                                break;
                            case 'nico':
                                uiState.animStart = 96;
                                uiState.animEnd = 97;
                                uiState.animDirection = 'reverse';
                                uiState.animSpeed = 1.0;
                                break;
                            case 'about':
                                uiState.animStart = 35;
                                uiState.animEnd = 36;
                                uiState.animDirection = 'reverse';
                                uiState.animSpeed = 0.5;
                                break;
                            case 'contact':
                                uiState.animStart = 40;
                                uiState.animEnd = 41;
                                uiState.animDirection = 'normal';
                                uiState.animSpeed = 0.75;
                                break;
                            case 'order':
                                uiState.animStart = 173.0;
                                uiState.animEnd = 174;
                                uiState.animDirection = 'reverse';
                                uiState.animSpeed = 0.4;
                                break;
                            case 'nft':
                                uiState.animStart = 164.0;
                                uiState.animEnd = 165.0;
                                uiState.animDirection = 'normal';
                                uiState.animSpeed = 0.25;
                                break;
                            default:
                                break;
                        }
                        uiState.animLocation = 'david';
                        uiState.forward = 'nico';
                        uiState.back = 'brett';
                        break;
                    case '/nico':
                        switch (uiState.animLocation) {
                            case '/':
                                uiState.animStart = 212.0;
                                uiState.animEnd = 213.0;
                                uiState.animDirection = 'normal';
                                uiState.animSpeed = 0.25;
                                break;
                            case 'front':
                                uiState.animStart = 203.0;
                                uiState.animEnd = 204.0;
                                uiState.animDirection = 'reverse';
                                uiState.animSpeed = 0.5;
                                break;
                            case 'about':
                                uiState.animStart = 201;
                                uiState.animEnd = 202;
                                uiState.animDirection = 'reverse';
                                uiState.animSpeed = 0.5;
                                break;
                            case 'create':
                                uiState.animStart = 213.0;
                                uiState.animEnd = 215.0;
                                uiState.animDirection = 'reverse';
                                uiState.animSpeed = 0.6;
                                break;
                            case 'wine':
                                uiState.animStart = 228.0;
                                uiState.animEnd = 230.0;
                                uiState.animDirection = 'normal';
                                uiState.animSpeed = 0.4;
                                break;
                            case 'david':
                                uiState.animStart = 96;
                                uiState.animEnd = 97;
                                uiState.animDirection = 'normal';
                                uiState.animSpeed = 1.0;
                                break;
                            case 'bozana':
                                uiState.animStart = 97;
                                uiState.animEnd = 98;
                                uiState.animDirection = 'reverse';
                                uiState.animSpeed = 1.0;
                                break;
                            case 'nft':
                                uiState.animStart = 231.0;
                                uiState.animEnd = 232.0;
                                uiState.animDirection = 'normal';
                                uiState.animSpeed = 0.25;
                                break;
                            default:
                                break;
                        }
                        uiState.animLocation = 'nico';
                        uiState.forward = 'bozana';
                        uiState.back = 'david';
                        break;
                    case '/bozana':
                        switch (uiState.animLocation) {
                            case '/':
                                uiState.animStart = 210.0;
                                uiState.animEnd = 211.0;
                                uiState.animDirection = 'normal';
                                uiState.animSpeed = 0.25;
                                break;
                            case 'front':
                                uiState.animStart = 204.0;
                                uiState.animEnd = 205.0;
                                uiState.animDirection = 'normal';
                                uiState.animSpeed = 0.5;
                                break;
                            case 'about':
                                uiState.animStart = 199;
                                uiState.animEnd = 200;
                                uiState.animDirection = 'reverse';
                                uiState.animSpeed = 0.5;
                                break;
                            case 'create':
                                uiState.animStart = 216.0;
                                uiState.animEnd = 218.0;
                                uiState.animDirection = 'reverse';
                                uiState.animSpeed = 0.6;
                                break;
                            case 'wine':
                                uiState.animStart = 225.0;
                                uiState.animEnd = 227.0;
                                uiState.animDirection = 'normal';
                                uiState.animSpeed = 0.4;
                                break;
                            case 'nico':
                                uiState.animStart = 97;
                                uiState.animEnd = 98;
                                uiState.animDirection = 'normal';
                                uiState.animSpeed = 1.0;
                                break;
                            case 'lee':
                                uiState.animStart = 98;
                                uiState.animEnd = 99;
                                uiState.animDirection = 'reverse';
                                uiState.animSpeed = 1.0;
                                break;
                            case 'nft':
                                uiState.animStart = 233.0;
                                uiState.animEnd = 234.0;
                                uiState.animDirection = 'normal';
                                uiState.animSpeed = 0.25;
                                break;
                            default:
                                break;
                        }
                        uiState.animLocation = 'bozana';
                        uiState.forward = 'lee';
                        uiState.back = 'nico';
                        break;
                    case '/lee':
                        switch (uiState.animLocation) {
                            case '/':
                                uiState.animStart = 208.0;
                                uiState.animEnd = 209.0;
                                uiState.animDirection = 'normal';
                                uiState.animSpeed = 0.25;
                                break;
                            case 'front':
                                uiState.animStart = 206.0;
                                uiState.animEnd = 207.0;
                                uiState.animDirection = 'normal';
                                uiState.animSpeed = 0.5;
                                break;
                            case 'about':
                                uiState.animStart = 197;
                                uiState.animEnd = 198;
                                uiState.animDirection = 'reverse';
                                uiState.animSpeed = 0.5;
                                break;
                            case 'create':
                                uiState.animStart = 219.0;
                                uiState.animEnd = 221.0;
                                uiState.animDirection = 'reverse';
                                uiState.animSpeed = 0.6;
                                break;
                            case 'wine':
                                uiState.animStart = 222.0;
                                uiState.animEnd = 224.0;
                                uiState.animDirection = 'normal';
                                uiState.animSpeed = 0.4;
                                break;
                            case 'bozana':
                                uiState.animStart = 98;
                                uiState.animEnd = 99;
                                uiState.animDirection = 'normal';
                                uiState.animSpeed = 1.0;
                                break;
                            case 'contact':
                                uiState.animStart = 99;
                                uiState.animEnd = 100;
                                uiState.animDirection = 'reverse';
                                uiState.animSpeed = 1.0;
                                break;
                            case 'nft':
                                uiState.animStart = 235.0;
                                uiState.animEnd = 236.0;
                                uiState.animDirection = 'normal';
                                uiState.animSpeed = 0.25;
                                break;
                            default:
                                break;
                        }
                        uiState.animLocation = 'lee';
                        uiState.forward = 'contact';
                        uiState.back = 'bozana';
                        break;
                    case '/contact':
                        switch (uiState.animLocation) {
                            case '/':
                                uiState.animStart = 137;
                                uiState.animEnd = 138;
                                uiState.animDirection = 'reverse';
                                uiState.animSpeed = 0.25;
                                break;
                            case 'front':
                                uiState.animStart = 137;
                                uiState.animEnd = 138;
                                uiState.animDirection = 'reverse';
                                uiState.animSpeed = 0.25;
                                break;
                            case 'lee':
                                uiState.animStart = 99;
                                uiState.animEnd = 100;
                                uiState.animDirection = 'normal';
                                uiState.animSpeed = 1.0;
                                break;
                            case 'about':
                                uiState.animStart = 39;
                                uiState.animEnd = 40;
                                uiState.animDirection = 'normal';
                                uiState.animSpeed = 0.75;
                                break;
                            case 'nft':
                                uiState.animStart = 133.0;
                                uiState.animEnd = 134;
                                uiState.animDirection = 'normal';
                                uiState.animSpeed = 0.4;
                                break;
                            case 'wine':
                                uiState.animStart = 134.0;
                                uiState.animEnd = 136;
                                uiState.animDirection = 'reverse';
                                uiState.animSpeed = 0.6;
                                break;

                            case 'create':
                                uiState.animStart = 141.0;
                                uiState.animEnd = 143;
                                uiState.animDirection = 'reverse';
                                uiState.animSpeed = 0.75;
                                break;
                            default:
                                break;
                        }
                        uiState.animLocation = 'contact';
                        uiState.forward = 'about';
                        uiState.back = 'lee';
                        break;
                    case '/thankyou':
                        switch (uiState.animLocation) {
                            case '/':
                                uiState.animStart = 70.0;
                                uiState.animEnd = 71.0;
                                uiState.animDirection = 'reverse';
                                uiState.animSpeed = 0.25;
                                break;
                            case 'front':
                                uiState.animStart = 64.0;
                                uiState.animEnd = 65;
                                uiState.animDirection = 'reverse';
                                uiState.animSpeed = 0.75;
                                break;
                            case 'wine':
                                uiState.animStart = 189.0;
                                uiState.animEnd = 190.0;
                                uiState.animDirection = 'reverse';
                                uiState.animSpeed = 0.5;
                                break;
                            case 'nft':
                                uiState.animStart = 181.0;
                                uiState.animEnd = 182;
                                uiState.animDirection = 'normal';
                                uiState.animSpeed = 0.6;
                                break;
                            case 'ar':
                                uiState.animStart = 37.0;
                                uiState.animEnd = 38;
                                uiState.animDirection = 'reverse';
                                uiState.animSpeed = 1.0;
                                break;
                            case 'about':
                                uiState.animStart = 68.0;
                                uiState.animEnd = 69;
                                uiState.animDirection = 'reverse';
                                uiState.animSpeed = 0.5;
                                break;
                            case 'create':
                                uiState.animStart = 191.0;
                                uiState.animEnd = 193;
                                uiState.animDirection = 'reverse';
                                uiState.animSpeed = 0.75;
                                break;
                            default:
                                break;
                        }
                        uiState.animLocation = 'thankyou';
                        break;
                    case '/ar':
                        switch (uiState.animLocation) {
                            case '/':
                                uiState.animStart = 94.0;
                                uiState.animEnd = 95;
                                uiState.animDirection = 'reverse';
                                uiState.animSpeed = 0.25;
                                break;
                            case 'wine':
                                uiState.animStart = 185.0;
                                uiState.animEnd = 186;
                                uiState.animDirection = 'reverse';
                                uiState.animSpeed = 0.4;
                                break;
                            case 'about':
                                uiState.animStart = 90.0;
                                uiState.animEnd = 91;
                                uiState.animDirection = 'reverse';
                                uiState.animSpeed = 0.5;
                                break;
                            case 'front':
                                uiState.animStart = 187.0;
                                uiState.animEnd = 188;
                                uiState.animDirection = 'normal';
                                uiState.animSpeed = 0.75;
                                break;
                            case 'thankyou':
                                uiState.animStart = 37.0;
                                uiState.animEnd = 38;
                                uiState.animDirection = 'normal';
                                uiState.animSpeed = 1.0;
                                break;
                            case 'nft':
                                uiState.animStart = 183.0;
                                uiState.animEnd = 184;
                                uiState.animDirection = 'reverse';
                                uiState.animSpeed = 1.0;
                                break;
                            case 'create':
                                uiState.animStart = 195.0;
                                uiState.animEnd = 196;
                                uiState.animDirection = 'reverse';
                                uiState.animSpeed = 0.4;
                                break;
                            default:
                                break;
                        }
                        uiState.animLocation = 'ar';

                        break;
                    case '/nft':
                        switch (uiState.animLocation) {
                            case '/':
                                uiState.animStart = 101.0;
                                uiState.animEnd = 102;
                                uiState.animDirection = 'normal';
                                uiState.animSpeed = 0.5;
                                break;
                            case 'front':
                                uiState.animStart = 101.0;
                                uiState.animEnd = 102;
                                uiState.animDirection = 'normal';
                                uiState.animSpeed = 0.75;
                                break;
                            case 'about':
                                uiState.animStart = 102.0;
                                uiState.animEnd = 103;
                                uiState.animDirection = 'reverse';
                                uiState.animSpeed = 0.5;
                                break;
                            case 'order':
                                uiState.animStart = 180.0;
                                uiState.animEnd = 181;
                                uiState.animDirection = 'normal';
                                uiState.animSpeed = 0.5;
                                break;
                            case 'create':
                                uiState.animStart = 106.0;
                                uiState.animEnd = 108;
                                uiState.animDirection = 'reverse';
                                uiState.animSpeed = 0.5;
                                break;
                            case 'wine':
                                uiState.animStart = 131.0;
                                uiState.animEnd = 133;
                                uiState.animDirection = 'normal';
                                uiState.animSpeed = 0.75;
                                break;
                            case 'contact':
                                uiState.animStart = 133.0;
                                uiState.animEnd = 134;
                                uiState.animDirection = 'reverse';
                                uiState.animSpeed = 0.4;
                                break;
                            case 'matt':
                                uiState.animStart = 147.0;
                                uiState.animEnd = 148.0;
                                uiState.animDirection = 'normal';
                                uiState.animSpeed = 0.5;
                                break;
                            case 'brett':
                                uiState.animStart = 163.0;
                                uiState.animEnd = 164.0;
                                uiState.animDirection = 'normal';
                                uiState.animSpeed = 0.5;
                                break;
                            case 'david':
                                uiState.animStart = 164.0;
                                uiState.animEnd = 165.0;
                                uiState.animDirection = 'reverse';
                                uiState.animSpeed = 0.25;
                                break;
                            case 'nico':
                                uiState.animStart = 231.0;
                                uiState.animEnd = 232.0;
                                uiState.animDirection = 'reverse';
                                uiState.animSpeed = 0.25;
                                break;
                            case 'bozana':
                                uiState.animStart = 233.0;
                                uiState.animEnd = 234.0;
                                uiState.animDirection = 'reverse';
                                uiState.animSpeed = 0.25;
                                break;
                            case 'lee':
                                uiState.animStart = 235.0;
                                uiState.animEnd = 236.0;
                                uiState.animDirection = 'reverse';
                                uiState.animSpeed = 0.25;
                                break;
                            case 'thankyou':
                                uiState.animStart = 181.0;
                                uiState.animEnd = 182;
                                uiState.animDirection = 'reverse';
                                uiState.animSpeed = 0.6;
                                break;
                            case 'ar':
                                uiState.animStart = 183.0;
                                uiState.animEnd = 184;
                                uiState.animDirection = 'normal';
                                uiState.animSpeed = 1.0;
                                break;
                            case 'nft':
                                uiState.animStart = 237.0;
                                uiState.animEnd = 238;
                                uiState.animDirection = 'alternateReverse';
                                uiState.animSpeed = 0.04;
                                uiState.animLoop = Infinity;
                                break;
                            default:
                                break;
                        }
                        uiState.animLocation = 'nft';
                        break;
                    default:
                        //404 page
                        uiState.animStart = 104.0;
                        uiState.animEnd = 105;
                        uiState.animDirection = 'reverse';
                        uiState.animSpeed = 0.3;
                        break;
                }

                //
                // Animation Timeline Sequence Control
                //
                wineSheet1.sequence
                    .play({
                        range: [uiState.animStart, uiState.animEnd],
                        rate: uiState.animSpeed,
                        direction: uiState.animDirection,
                        iterationCount: uiState.animLoop
                    })
                    .then(() => {
                        if (
                            !uiState.homeShow &&
                            !uiState.optinShow &&
                            !uiState.customLabelUIShow
                        ) {
                            if (uiState.animLocation === '/') {
                                if (uiState.mintPopShow == false) {
                                    uiState.homeShow = true;
                                }
                                uiState.mapControls = false;
                                uiState.nftSceneActive = false;
                                return;
                            } else if (uiState.animLocation === 'front') {
                                if (uiState.mintPopShow == false) {
                                    // console.log('frontShow', uiState.frontShow);
                                    if (uiState.frontShow == 'optin') {
                                        uiState.optinShow = true;
                                        uiState.videoPlayerShow = false;
                                    }
                                    if (uiState.frontShow == 'video') {
                                        uiState.optinShow = false;
                                        uiState.videoPlayerShow = true;
                                    }
                                    uiState.animEndUpdate = true;
                                    uiState.mapControls = true;
                                    uiState.nftSceneActive = false;
                                }
                                return;
                            } else if (uiState.animLocation === 'create') {
                                uiState.customLabelUIShow = true;
                                uiState.mapControls = false;
                                uiState.scrollControls = false;
                                uiState.nftSceneActive = false;
                                return;
                            } else if (uiState.animLocation === 'order') {
                                uiState.customLabelUIShow = false;
                                uiState.mapControls = true;
                                uiState.nftSceneActive = false;
                                return;
                            } else if (
                                uiState.animLocation === 'nft' &&
                                !uiState.nftSceneActive
                            ) {
                                navigate('nft');
                                uiState.nftSceneActive = true;
                                uiState.customLabelUIShow = false;
                                uiState.mapControls = true;
                                return;
                            }
                            uiState.mapControls = true;
                        }
                        // console.log(
                        //     'animEnd',
                        //     uiState.animLocation,
                        //     '\n',
                        //     'scrollControls',
                        //     uiState.scrollControls
                        // );
                    });
            }
        });
        uiState.clickedState = false;
    }, [location]);
    return null;
};

export default AnimController;
