import { useState, useEffect, useRef } from 'react';
import { Text3D, useMatcapTexture, RoundedBox } from '@react-three/drei';
import { editable as e } from '@theatre/r3f';
import { uiState } from '../states/ui-state';

export function ThreeDButton(props) {
    const [hovered, setHovered] = useState(false);
    const over = (e) => (e.stopPropagation(), setHovered(true));
    const out = () => setHovered(false);

    //Setup Mouse hover
    useEffect(() => {
        if (hovered) document.body.style.cursor = 'pointer';
        return () => (document.body.style.cursor = 'auto');
    }, [hovered]);

    const openInNewTab = (url) => {
        window.open(url, '_blank', 'noreferrer');
    };

    const handleClick = (e, url) => {
        e.stopPropagation(e);

        openInNewTab(url);

        uiState.clickedState = true;
    };

    //TODO this uses 3rd party CDN for this type of texture, fix it for production
    const [matcapTextureGold] = useMatcapTexture(
        '71623B_ECDE8C_30250A_ABA69A',
        64
    );
    const [matcapTextureGlass] = useMatcapTexture(
        '293534_B2BFC5_738289_8A9AA7',
        64
    );
    const [matcapTextureWineRed] = useMatcapTexture(
        '430404_BD9295_7E1E21_94544C',
        64
    );
    const [matcapTextureChrome] = useMatcapTexture(
        '736655_D9D8D5_2F281F_B1AEAB',
        64
    );

    return (
        <>
            <group
                onClick={(e) => handleClick(e, props.url)}
                onPointerOver={over}
                onPointerOut={out}
            >
                <e.group theatreKey={props.theatreID + ' / Label'}>
                    <Text3D
                        font="./fonts/Montserrat_SemiBold_Regular.json"
                        size={props.fontSize}
                        height={0.03}
                    >
                        {props.label}
                        <meshMatcapMaterial matcap={matcapTextureGold} />
                    </Text3D>
                </e.group>
                <e.group theatreKey={props.theatreID + ' / Base'}>
                    <RoundedBox
                        args={[props.baseWidth, 0.03, 0.08]}
                        radius={0.01}
                        smoothness={8}
                        castShadow={true}
                        receiveShadow={true}
                    >
                        <meshMatcapMaterial matcap={matcapTextureWineRed} />
                    </RoundedBox>
                </e.group>
            </group>
        </>
    );
}
