import React, { useRef, useState, useEffect } from 'react';
import { useGLTF } from '@react-three/drei';
import { SparklesComponent } from './Sparkles';
import { uiState } from '../states/ui-state.js';
import { useSnapshot } from 'valtio';
import { editable as e } from '@theatre/r3f';
import { Text3D, useMatcapTexture, RoundedBox } from '@react-three/drei';

export function NftScene(props) {
    const snap = useSnapshot(uiState);
    const [hovered, setHovered] = useState(false);
    const over = (e) => (e.stopPropagation(), setHovered(true));
    const out = () => setHovered(false);

    const openseaCollectionLink =
        'https://opensea.io/collection/nftwineclubfounders';
    // const raribleCollectionLink =
    //     'https://testnet.rarible.com/collection/polygon/0x80f30df3001337a6191d62cc0ce1f7575c3d8b18/items';

    //Setup Mouse hover
    useEffect(() => {
        if (hovered) document.body.style.cursor = 'pointer';
        return () => (document.body.style.cursor = 'auto');
    }, [hovered]);

    const handleClick = (e, state) => {
        e.stopPropagation();
        if (state == 'mint') {
            uiState.mintPopShow = true;
        } else if (state == 'video') {
            uiState.videoPlayerShow = true;
            uiState.frontShow = state;
            uiState.animEndUpdate = true;
        }
    };

    const openInNewTab = (e, url) => {
        e.stopPropagation();
        window.open(url, '_blank', 'noreferrer');
    };

    //TODO this uses 3rd party CDN for this type of texture, fix it for production
    const [matcapTextureGold] = useMatcapTexture(
        '71623B_ECDE8C_30250A_ABA69A',
        64
    );
    const [matcapTextureGlass] = useMatcapTexture(
        '293534_B2BFC5_738289_8A9AA7',
        64
    );
    const [matcapTextureWineRed] = useMatcapTexture(
        '430404_BD9295_7E1E21_94544C',
        64
    );
    const [matcapTextureChrome] = useMatcapTexture(
        '736655_D9D8D5_2F281F_B1AEAB',
        64
    );

    const { nodes, materials } = useGLTF('/models/VINE-A1.glb');

    return (
        <group {...props} dispose={null}>
            <e.group theatreKey={props.theatreID + ' / Sparkles'}>
                <SparklesComponent />
            </e.group>
            {/* opensea button  */}
            <e.group
                theatreKey={props.theatreID + ' / Opensea Button'}
                scale={[1, 1, 1]}
                position={[0, 0, 0]}
                rotation={[0, 0, 0]}
                onClick={(e) => openInNewTab(e, openseaCollectionLink)}
                onPointerOver={over}
                onPointerOut={out}
            >
                <e.group
                    theatreKey={props.theatreID + ' / Opensea Button_Label'}
                    position={[-0.035, 0, 0.017]}
                    rotation={[-1.57, 0, 0]}
                >
                    <Text3D
                        font="./fonts/Montserrat_SemiBold_Regular.json"
                        size={0.028}
                        height={0.03}
                    >
                        {'VIEW COLLECTION'}
                        <meshMatcapMaterial matcap={matcapTextureGold} />
                    </Text3D>
                </e.group>
                <RoundedBox
                    args={[0.5, 0.05, 0.1]}
                    radius={0.01}
                    smoothness={8}
                    castShadow={true}
                    receiveShadow={true}
                >
                    <meshMatcapMaterial matcap={matcapTextureWineRed} />
                </RoundedBox>
            </e.group>
            {/* mint button  */}
            <e.group
                theatreKey={props.theatreID + ' / Mint Button'}
                scale={[1, 1, 1]}
                position={[0, 0, 0]}
                rotation={[0, 0, 0]}
                onClick={(e) => handleClick(e, 'mint')}
                onPointerOver={over}
                onPointerOut={out}
            >
                <e.group
                    theatreKey={props.theatreID + ' / Mint Button_Label'}
                    position={[-0.035, 0, 0.017]}
                    rotation={[-1.57, 0, 0]}
                >
                    <Text3D
                        font="./fonts/Montserrat_SemiBold_Regular.json"
                        size={0.028}
                        height={0.03}
                    >
                        {'CLAIM FOUNDER NFT'}
                        <meshMatcapMaterial matcap={matcapTextureGold} />
                    </Text3D>
                </e.group>
                <RoundedBox
                    args={[0.5, 0.05, 0.1]}
                    radius={0.01}
                    smoothness={8}
                    castShadow={true}
                    receiveShadow={true}
                >
                    <meshMatcapMaterial matcap={matcapTextureWineRed} />
                </RoundedBox>
            </e.group>
            {/* video button  */}
            <e.group
                theatreKey={props.theatreID + ' / Video Button'}
                scale={[1, 1, 1]}
                position={[0, 0, 0]}
                rotation={[0, 0, 0]}
                onClick={(e) => handleClick(e, 'video')}
                onPointerOver={over}
                onPointerOut={out}
            >
                <e.group
                    theatreKey={props.theatreID + ' / Video Button_Label'}
                    position={[-0.035, 0, 0.017]}
                    rotation={[-1.57, 0, 0]}
                >
                    <Text3D
                        font="./fonts/Montserrat_SemiBold_Regular.json"
                        size={0.028}
                        height={0.03}
                    >
                        {'WATCH VIDEO'}
                        <meshMatcapMaterial matcap={matcapTextureGold} />
                    </Text3D>
                </e.group>
                <RoundedBox
                    args={[0.5, 0.05, 0.1]}
                    radius={0.01}
                    smoothness={8}
                    castShadow={true}
                    receiveShadow={true}
                >
                    <meshMatcapMaterial matcap={matcapTextureWineRed} />
                </RoundedBox>
            </e.group>
            <mesh
                name="Bottle_Bottle001_Bottle_Bottle002"
                castShadow
                receiveShadow
                geometry={nodes.Bottle_Bottle001_Bottle_Bottle002.geometry}
                material={materials.Glass}
                position={[0.96, 0.98, 0.34]}
                rotation={[-Math.PI, 0, -Math.PI]}
                scale={1.31}
            >
                <mesh
                    name="Bottle_Bottle001_Bottle_Wine002"
                    castShadow
                    receiveShadow
                    geometry={nodes.Bottle_Bottle001_Bottle_Wine002.geometry}
                    material={materials.wine_red}
                    position={[0, 1.29, 0]}
                />
                <mesh
                    name="Bottle_Bottle001_Foil002_Baked"
                    castShadow
                    receiveShadow
                    geometry={nodes.Bottle_Bottle001_Foil002_Baked.geometry}
                    material={materials['CW-Gold-Matte.001']}
                    position={[0, 4.63, 0]}
                    rotation={[Math.PI, 0, Math.PI]}
                    scale={0.9}
                />
                <mesh
                    name="Bottle_Bottle001_Bottle_Cord002_Baked"
                    castShadow
                    receiveShadow
                    geometry={
                        nodes.Bottle_Bottle001_Bottle_Cord002_Baked.geometry
                    }
                    material={
                        materials['Bottle_Bottle.001_Bottle_Cord.002_Baked']
                    }
                    position={[-0.17, 4.28, -0.16]}
                    rotation={[-0.22, 0, 0.16]}
                    scale={0.61}
                />
                <mesh
                    name="Bottle_Bottle001_Bottle_Tag002"
                    castShadow
                    receiveShadow
                    geometry={nodes.Bottle_Bottle001_Bottle_Tag002.geometry}
                    material={materials['Material.002']}
                    position={[-0.79, 3.57, -0.02]}
                    rotation={[1.2, -0.14, -2.8]}
                />
                <mesh
                    name="Bottle_Bottle001_Bottle_Label002_Baked"
                    castShadow
                    receiveShadow
                    geometry={
                        nodes.Bottle_Bottle001_Bottle_Label002_Baked.geometry
                    }
                    material={
                        materials['Bottle_Bottle.001_Bottle_Label.002_Baked']
                    }
                    position={[-0.75, 1.93, -0.01]}
                />
            </mesh>
            <mesh
                name="Logo_Logo001_Logo_Logo002"
                castShadow
                receiveShadow
                geometry={nodes.Logo_Logo001_Logo_Logo002.geometry}
                material={materials['CW-Gold-Matte']}
                position={[-0.2, 1.73, 3.53]}
                rotation={[0, -1.19, 0]}
                scale={1.76}
            />
            <mesh
                name="Crate_Crate001_Box-Logo001"
                castShadow
                receiveShadow
                geometry={nodes['Crate_Crate001_Box-Logo001'].geometry}
                material={materials['CW-Gold-Matte']}
                position={[-4.06, 5.48, 0]}
                rotation={[Math.PI / 2, 0, 1.56]}
                scale={29.01}
            />
            <mesh
                name="Crate_Crate001_Box-Logo002"
                castShadow
                receiveShadow
                geometry={nodes['Crate_Crate001_Box-Logo002'].geometry}
                material={materials['Material.003']}
                position={[-1.6, 5.9, -2.17]}
                rotation={[1.64, 0.08, -2.24]}
                scale={22.65}
            />
            <mesh
                name="Table_Table001_Baked"
                castShadow
                receiveShadow
                geometry={nodes.Table_Table001_Baked.geometry}
                material={materials['Table_Table.001_Baked.001']}
                position={[0, 0.46, 0]}
            />
            <mesh
                name="Crate_Crate001_Crate_Crate002_Baked"
                castShadow
                receiveShadow
                geometry={nodes.Crate_Crate001_Crate_Crate002_Baked.geometry}
                material={materials['Wood Plain Light 010_1k']}
                position={[-2.69, 0.98, 0]}
                rotation={[-Math.PI, 0, -Math.PI]}
                scale={1.19}
            />
            <mesh
                name="Crate_Crate001_Crate_Lid002_Baked"
                castShadow
                receiveShadow
                geometry={nodes.Crate_Crate001_Crate_Lid002_Baked.geometry}
                material={materials['Wood Plain Light 010_1k']}
                position={[-1.2, 0.99, -2.38]}
                rotation={[3.14, -0.66, 3.03]}
                scale={[1.19, 3.63, 1.19]}
            />
            <mesh
                name="Vine_Vine001_Baked"
                castShadow
                receiveShadow
                geometry={nodes.Vine_Vine001_Baked.geometry}
                material={materials['Vine_Vine.001_Baked']}
                position={[-0.82, 0, -4.55]}
                rotation={[3.05, 0.6, 3.09]}
                scale={1.59}
            />
            <mesh
                name="Crate_Crate001_Crate_Qr-Top001"
                castShadow
                receiveShadow
                geometry={nodes['Crate_Crate001_Crate_Qr-Top001'].geometry}
                material={
                    materials['219748540-54841583-c861-451b-9bba-940a8f395da7']
                }
                position={[-1.41, 4.3, -2.21]}
                rotation={[-1.5, -0.08, 2.24]}
                scale={1.4}
            />
            <mesh
                name="Crate_Crate001_Crate_Qr-Top002"
                castShadow
                receiveShadow
                geometry={nodes['Crate_Crate001_Crate_Qr-Top002'].geometry}
                material={
                    materials['219748540-54841583-c861-451b-9bba-940a8f395da7']
                }
                position={[-2.85, 9.42, 0]}
                rotation={[-Math.PI, -1.57, 0]}
                scale={1.4}
            />
        </group>
    );
}

useGLTF.preload('/models/VINE-A1.glb');
