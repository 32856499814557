import { proxy, ref } from 'valtio';
import { createRef } from 'react';

const uiState = proxy({
    optinShow: false,
    labelUIShow: false,
    optinReveal: true,
    homeShow: false,
    homeReveal: true,
    footerShow: false,
    customLabelUIShow: false,
    customLabelImageSet: false,
    animLocation: '/',
    animTarget: 'load',
    animLoop: 1,
    nftSceneActive: false,
    back: 'about',
    forward: 'team',
    clickedState: 'false',
    bottleFoilColor: '#d10000',
    showDrawer: false,
    cookiePopShow: false,
    mintPopShow: false,
    mintStatusShow: false,
    mintTransmitShow: false,
    nftID: 0,
    isMobile: false,
    mapControls: false,
    videoPlayerShow: false,
    frontShow: 'optin',
    scrollControls: true,
    animEndUpdate: false,

    //web3 stuff
    nftOwner: '0x000',
    mintTransactionHash: '0x000'
});

export const filePickerState = proxy({
    filePickerRef: ref(createRef())
});
export const colorPickerState = proxy({
    colorPickerRef: ref(createRef())
});
export const videoPlayerProxy = proxy({
    videoPlayerRef: ref(createRef())
});

export { uiState };
